/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b3d5e06f-8b35-4d5a-b2d8-9fc95f6d4acd",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XgYS6Mogf",
    "aws_user_pools_web_client_id": "3eh2eah11t76p35hcgfpofe0h8",
    "oauth": {
        "domain": "nikociamcc907905-cc907905-develop.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d3gr0y2ao60iew.cloudfront.net/,https://djp7vrcaujwrf.cloudfront.net/,https://dt6l4vucwj7uc.cloudfront.net/",
        "redirectSignOut": "https://d3gr0y2ao60iew.cloudfront.net/,https://djp7vrcaujwrf.cloudfront.net/,https://dt6l4vucwj7uc.cloudfront.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "nikociam-20221022161033-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2ddycnzov44m.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "nikoUserCheck",
            "endpoint": "https://k34qwckjti.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "wrapperApi",
            "endpoint": "https://q9djo7apwe.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "nikoCiamExternalApi",
            "endpoint": "https://ooyvp0xhq5.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
