import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  buttonRoot: {
    color: '#F6F6F6',
    height: '50px',
    marginTop: 22,
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  },
  buttonSuccess: {
    height: '50px',
    marginTop: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 0,
    marginLeft: -12,
  },
}));

interface LoaderButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  success?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const LoaderButton: React.FC<LoaderButtonProps> = ({
  children,
  onClick = (): void => {},
  success = false,
  loading = false,
  disabled = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          classes={{ root: success ? classes.buttonSuccess : classes.buttonRoot }}
          disabled={disabled ?? loading}
          onClick={onClick}
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          {...rest}
        >
          {!loading && (children || null)}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
};

export default LoaderButton;
