export default {
  send: 'Skicka',
  email: 'E-postadress',
  back: 'Tillbaka',
  signWithEmail: 'Registrera dig med e-post',
  tabLogin: 'Logga in',
  tabSign: 'Registrera',
  newPassword: 'Nytt lösenord',
  newEmailTextField: 'Ny e-post',
  confirmNewEmailTextField: 'Bekräfta ny e-post',

  confirm: {
    confirm: 'Bekräfta',
    finish: 'Slutförande',
    code: 'Verifieringskod',
    title: 'Vänligen fyll i verifieringskoden',
    text1: 'Vi har just skickat ett e-postmeddelande till dig.',
    text2: 'Kontrollera din brevlåda och följ instruktionerna för att verifiera ditt konto.',
    text3: 'Observera att e-postmeddelandet kan finnas i din skräppostmapp.',
    required: 'Verifieringskoden är ett obligatoriskt fält',
    resendCode: 'Återsändningskod',
    resendCodePrompt: 'En ny verifieringskod har skickats, kontrollera din inkorg.',
  },

  newpassword: {
    title: 'Ange ditt nya lösenord',
    minRequirements: 'Minimikrav: 8 tecken, 1 stor bokstav, 1 liten bokstav, 1 siffra.',
  },

  button: {
    cancel: 'Avbryt',
    cancelRequest: 'Avbryt begäran',
    continue: 'Fortsätt',
    next: 'Nästa',
    finish: 'Avsluta',
  },

  login: {
    password: 'Lösenord',
    connect: 'Logga in',
    connectUsing: 'Logga in med hjälp av',
    withEmail: 'eller via e-post',
    passwordHelp: 'Minimikrav: 8 tecken, 1 stor bokstav, 1 liten bokstav, 1 siffra.',
    logout: 'Logga ut',
    remember: 'Kom ihåg min information',
    forgot: 'Glömt lösenord',
  },

  signup: {
    with: 'Registrera dig hos ',
    or: 'eller',
  },

  reset: {
    resetPassword: 'Har du glömt ditt lösenord?',
    resetPasswordText: `Ange den e-postadress som är kopplad till ditt konto. 
Vi kommer att skicka dig en verifieringskod för att återställa ditt lösenord.`,
    temporaryPassword: 'Kontrollera din brevlåda för att få ett tillfälligt lösenord.',
    confirmResetPasswordText1: 'En verifieringskod har skickats till:',
    confirmResetPasswordText2: `Kontrollera din brevlåda och följ 
    instruktionerna för att återställa ditt lösenord.`,
    confirmResetPasswordText3: 'Observera att e-postmeddelandet kan finnas i din skräppostmapp.',
    goToLogin: 'Gå tillbaka till inloggningen',
  },

  mailSignup: {
    signUp: 'Registrera',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    choosePassword: 'Lösenord',
    hide: 'Dölj',
    show: 'Visa',
    passwordHelp: 'Minimikrav: 8 tecken, 1 stor bokstav, 1 liten bokstav, 1 siffra.',
    country: 'Land',
    language: 'Språk',
    receiveInfo: 'Jag vill få information om Niko',
    textPrivacyPolicy:
      'Genom att skicka in detta formulär godkänner du att Niko får behandla dina personuppgifter i enlighet med vår ',
    privacyPolicy: 'integritetspolicy',
    termsOfUse1: 'Jag förstår och accepterar följande',
    termsOfUse2: 'Användarvillkor',
    mandatoryFields: 'Obligatoriska fält',
    dataTreatment: ' dess sekretesspolicy',
  },

  language: {
    french: 'Franska',
    english: 'Engelska',
    swedish: 'Svenska',
    danish: 'Danska',
    slovak: 'Slovakiska',
    dutch: 'Nederländska',
    german: 'Tyska',
  },

  country: {
    belgium: 'Belgien',
    denmark: 'Danmark',
    france: 'Frankrike',
    sweden: 'Sverige',
    slovakia: 'Slovakien',
    germany: 'Tyskland',
    netherland: 'Nederland',
  },

  error: {
    required: 'Obligatoriskt fält',
    apicall: 'Något gick fel på api-servern!',
    UserNotFoundException: 'Användaren finns inte.',
    NotAuthorizedException: 'Felaktigt användarnamn eller lösenord.',
    NotAuthorizedExceptionReset: 'Felaktigt användarnamn.',
    UsernameExistsException: 'Det finns redan ett konto med den angivna e-postadressen.',
    InvalidParameterExceptionPassword: `Lösenordet överensstämde inte med policyn: Lösenordet måste :
        ha en längd som är större än eller lika med 8, 
        innehålla 1 stor och 1 liten bokstav,
        1 siffra.`,
    InvalidPasswordExceptionLong: 'Lösenordet överensstämde inte med policyn: Lösenordet var inte tillräckligt långt.',
    InvalidPasswordExceptionLower:
      'Lösenordet överensstämde inte med policyn: Lösenordet måste innehålla små bokstäver.',
    InvalidPasswordExceptionUpper:
      'Lösenordet överensstämde inte med policyn: Lösenordet måste innehålla stora bokstäver.',
    InvalidPasswordExceptionNumeric:
      'Lösenordet överensstämde inte med policyn: Lösenordet måste innehålla numeriska tecken.',
    CodeMismatchException: 'Ogiltig verifieringskod, försök igen.',
    email: 'Saknas',
    confirmationCode: 'Saknas',
    AuthError: 'Fel bekräftelsekod',
    crmError: 'Ett oväntat fel inträffade under inloggningen, kontakta Niko för att lösa problemet.',
    invalidPassword: 'Ditt lösenord har gått ut, du har fått ett e-postmeddelande med ytterligare instruktioner.',
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'E-postmeddelandena måste stämma överens',
      newEmailBasic: 'Ogiltigt fält',
      newEmailWrongFormat: 'Inte ett giltigt e-postformat',
      newEmailUserNotFound: 'Du måste vara inloggad för att kunna uppdatera din e-post.',
    },
  },
};
