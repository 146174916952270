import React, { useMemo } from 'react';

const useDeviceDetect = () => {
  const [isMobile, setMobile] = React.useState<boolean>(false);

  React.useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
    setMobile(mobile);
  }, []);
  const value = useMemo(() => ({ isMobile }), [isMobile]);
  return value;
};
export default useDeviceDetect;
