import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Container, FormControl, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { AuthError, useUser } from 'hooks/UserContext';
import { useRedirect } from 'hooks/RedirectContext';
import { useLocale } from 'i18n/LocaleContext';
import LoaderButton from 'components/Buttons/LoaderButton';
import ResendButtonWrapper from './ResendEmailChangeButtonWrapper';

interface ConfirmEmailChangeValues {
  confirmationCode: string;
  defaultErrorMessage?: string;
}

const ConfirmEmailChangeSchema = Yup.object().shape({
  confirmationCode: Yup.string().required(),
});

export const appBarHeight = 64;
const useStyles = makeStyles((theme: Theme) => ({
  confirmTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },
  backBar: {
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(242, 242, 242, 1)',
    },
    height: 54,
    background: 'rgba(77, 77, 79, 1)',
  },
  backToolBar: {
    height: 54,
    verticalAlign: 'center',
    padding: 0,
  },
  confirmBody: {
    paddingTop: 40,
    padding: 48,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  buttonRoot: {
    width: '100%',
    color: '#F6F6F6',
    height: '50px',
    marginTop: 22,
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  },
}));

const ChangeEmailConfirm: React.FC<RouteChildrenProps> = ({ history }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { user, confirmUpdateEmail, cancelUpdateEmail } = useUser();
  const { getRefererUri, getRedirectUri } = useRedirect();

  const { langAndCountryFormated } = useLocale();

  const handleSubmit = async (
    values: ConfirmEmailChangeValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<ConfirmEmailChangeValues>,
  ) => {
    try {
      if (!user) {
        throw new ReferenceError('newEmailUserNotFound');
      }
      await confirmUpdateEmail(user, values.confirmationCode);
      const refererUri = getRefererUri();
      const redirectUri = getRedirectUri();
      // eslint-disable-next-line no-console
      console.log(getRefererUri());
      if (refererUri) {
        window.location.replace(refererUri);
      } else if (redirectUri) {
        window.location.replace(redirectUri);
      } else {
        history.push(`/${langAndCountryFormated}/exit`);
      }
    } catch (e) {
      const err = e as AuthError;
      if (err.message === 'newEmailUserNotFound')
        setErrors({ confirmationCode: err.name, defaultErrorMessage: formatMessage({ id: `error.${err.message}` }) });
      else {
        setErrors({ confirmationCode: err.name, defaultErrorMessage: err.message });
      }
    } finally {
      setStatus(true);
      setSubmitting(false);
    }
  };

  const handleCancel = async () => {
    try {
      if (!user) {
        throw new ReferenceError('newEmailUserNotFound');
      }
      await cancelUpdateEmail(user);
    } catch (e) {
      const { setErrors } = useFormikContext();
      const err = e as AuthError;
      if (err.message === 'newEmailUserNotFound')
        setErrors({ confirmationCode: err.name, defaultErrorMessage: formatMessage({ id: `error.${err.message}` }) });
      else {
        setErrors({ confirmationCode: err.name, defaultErrorMessage: err.message });
      }
    } finally {
      const refererUri = getRefererUri();
      const redirectUri = getRedirectUri();
      if (refererUri) {
        window.location.replace(refererUri);
      }
      if (redirectUri) {
        window.location.replace(redirectUri);
      }
      history.push(`/${langAndCountryFormated}/exit`);
    }
  };

  return (
    <Formik
      initialValues={
        {
          confirmationCode: '',
          defaultErrorMessage: '',
        } as ConfirmEmailChangeValues
      }
      validationSchema={ConfirmEmailChangeSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Container component="main" maxWidth={false} className={classes.confirmTab}>
          <AppBar position="relative" className={classes.backBar}>
            <Toolbar variant="dense" className={classes.backToolBar} />
          </AppBar>
          <Container className={classes.confirmBody}>
            <Form>
              <FormControl style={{ marginTop: 32 }} fullWidth>
                <Typography variant="h3">{formatMessage({ id: 'confirm.code' })}</Typography>
                <TextField
                  id="confirmationCode"
                  type="text"
                  name="confirmationCode"
                  margin="normal"
                  variant="outlined"
                  value={values.confirmationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    classes: {
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorText,
                    },
                  }}
                  error={Boolean(errors.confirmationCode && touched.confirmationCode)}
                  helperText={
                    errors.confirmationCode === 'AuthError' && touched.confirmationCode
                      ? formatMessage({
                          id: `error.${errors.confirmationCode}`,
                        })
                      : formatMessage({ id: 'confirm.required' })
                  }
                  fullWidth
                />
              </FormControl>

              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({ id: 'confirm.finish' })}
              </LoaderButton>

              <Button className={classes.buttonRoot} color="primary" variant="contained" onClick={handleCancel}>
                {formatMessage({ id: 'button.cancelRequest' })}
              </Button>

              <ResendButtonWrapper />
            </Form>
          </Container>
        </Container>
      )}
    </Formik>
  );
};

export default ChangeEmailConfirm;
