import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useRedirect, removeRedirectUriFromCache, isRedirectUriValid } from 'hooks/RedirectContext';
import { useUser } from 'hooks/UserContext';
import { useLocale } from 'i18n/LocaleContext';

import ExitCard from 'pages/Authenticated/Exit/ExitCard';
import { useIntl } from 'react-intl';

const Logout: React.FC = () => {
  const history = useHistory();
  const { logout } = useUser();
  const { langAndCountryFormated } = useLocale();
  const { getRedirectUri } = useRedirect();
  const { formatMessage } = useIntl();

  useEffect(() => {
    logout().then(() => {
      const redirectUri = getRedirectUri();
      if (redirectUri !== null && isRedirectUriValid(redirectUri)) {
        removeRedirectUriFromCache();
        window.location.replace(redirectUri);
      } else {
        history.push(`/${langAndCountryFormated}/login`);
      }
    });
  }, []);

  return <ExitCard title={formatMessage({ id: 'exit.logoutTitle' })} />;
};

export default Logout;
