export default {
  send: 'Senden',
  email: 'E-mail',
  back: 'Zurück',
  signWithEmail: 'Registrieren mit E-mail',
  tabLogin: 'Anmeldung',
  tabSign: 'Registrieren',
  newPassword: 'Neues Kennwort',
  newEmailTextField: 'Neue E-mail',
  confirmNewEmailTextField: 'Neue E-Mail bestätigen',

  confirm: {
    confirm: 'Bestätigen Sie',
    finish: 'Beenden Sie',
    code: 'Verifizierungscode',
    title: 'Bitte geben Sie den Verifizierungscode ein',
    text1: 'Wir haben Ihnen gerade eine E-Mail geschickt.',
    text2: 'Bitte überprüfen Sie Ihre Mailbox und folgen Sie den Anweisungen, um Ihr Konto zu verifizieren.',
    text3: 'Beachten Sie, dass sich die E-Mail möglicherweise in Ihrem Spam-Ordner befindet.',
    required: 'Der Verifizierungscode ist ein Pflichtfeld',
    resendCode: 'Code erneut senden',
    resendCodePrompt: 'Ein neuer Verifizierungscode wurde versandt, bitte überprüfen Sie Ihren Posteingang.',
  },

  newpassword: {
    title: 'Bitte geben Sie Ihr neues Passwort ein',
    minRequirements: 'Mindestanforderungen: 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl',
  },

  button: {
    cancel: 'Abbrechen',
    cancelRequest: 'Anfrage stornieren',
    continue: 'Weiter',
    next: 'Weiter',
    finish: 'Beenden Sie',
  },

  login: {
    password: 'Passwort',
    connect: 'Einloggen',
    connectUsing: 'Anmelden mit',
    withEmail: 'oder mit E-Mail Adresse',
    passwordHelp: 'Mindestanforderungen: 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl',
    logout: 'Abmelden',
    remember: 'Meine Informationen speichern',
    forgot: 'Vergessenes Passwort	',
  },

  signup: {
    with: 'Anmelden bei ',
    or: 'oder',
  },

  reset: {
    resetPassword: 'Haben Sie Ihr Passwort vergessen?',
    resetPasswordText: `Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein. 
Wir senden Ihnen einen Bestätigungscode, mit dem Sie Ihr Passwort zurücksetzen können.`,
    temporaryPassword: 'Bitte prüfen Sie Ihre Mailbox auf ein temporäres Passwort',
    confirmResetPasswordText1: 'Ein Verifizierungscode wurde an folgende Adresse gesendet:',
    confirmResetPasswordText2:
      'Bitte prüfen Sie Ihre Mailbox und folgen Sie den Anweisungen, um Ihr Passwort zurückzusetzen. ',
    confirmResetPasswordText3: 'Beachten Sie, dass sich die E-Mail möglicherweise in Ihrem Spam-Ordner befindet.',
    goToLogin: 'Zurück zur Anmeldung',
  },

  mailSignup: {
    signUp: 'Register',
    firstName: 'Vornamen',
    lastName: 'Nachname',
    choosePassword: 'Passwort',
    hide: 'Ausblenden',
    show: 'anzeigen',
    passwordHelp: 'Mindestanforderungen: 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl',
    country: 'Land',
    language: 'Sprache',
    receiveInfo: 'Ich möchte Informationen über Niko erhalten',
    textPrivacyPolicy: `Mit dem Absenden dieses Formulars erklären Sie sich damit einverstanden, 
      dass Niko Ihre persönlichen Daten gemäß unseren Bestimmungen verarbeitet.`,
    privacyPolicy: 'Datenschutzbestimmungen',
    termsOfUse1: 'Ich verstehe und akzeptiere die',
    termsOfUse2: 'Benutzungsbedingungen',
    mandatoryFields: 'Obligatorische Felder',
    dataTreatment: ' seine Datenschutzpolitik',
  },

  language: {
    french: 'Französisch',
    english: 'Englisch',
    swedish: 'Schwedisch',
    danish: 'Dänisch',
    slovak: 'Slowakisch',
    dutch: 'Niederländisch',
    german: 'Deutsch',
  },

  country: {
    belgium: 'Belgien',
    denmark: 'Dänemark',
    france: 'Frankreich',
    sweden: 'Schweden',
    slovakia: 'Slowakei',
    germany: 'Deutschland',
    netherland: 'Niederlande',
  },

  error: {
    required: 'Erforderliches Feld',
    apicall: 'Auf dem Api-Server ist etwas schief gelaufen!',
    UserNotFoundException: 'Der Benutzer existiert nicht.',
    NotAuthorizedException: 'Falscher Benutzername oder falsches Passwort.',
    NotAuthorizedExceptionReset: 'Falscher Benutzername.',
    UsernameExistsException: 'Ein Konto mit der angegebenen E-Mail existiert bereits.',
    InvalidParameterExceptionPassword: `Das Passwort entsprach nicht der Richtlinie: Das Passwort muss :
        eine Länge größer oder gleich 8 haben, 
        1 Groß- und Kleinbuchstaben enthalten,
        1 Zahl.`,
    InvalidPasswordExceptionLong: 'Das Passwort entsprach nicht den Richtlinien: Das Passwort ist nicht lang genug.',
    InvalidPasswordExceptionLower:
      'Das Passwort entsprach nicht den Richtlinien: Das Passwort muss Kleinbuchstaben enthalten.',
    InvalidPasswordExceptionUpper:
      'Das Passwort entsprach nicht den Richtlinien: Das Passwort muss Großbuchstaben enthalten.',
    InvalidPasswordExceptionNumeric:
      'Das Passwort entsprach nicht den Richtlinien: Das Passwort muss numerische Zeichen enthalten.',
    CodeMismatchException: 'Der angegebene Verifizierungscode ist ungültig, bitte versuchen Sie es erneut.',
    email: 'Fehlt',
    confirmationCode: 'Fehlt',
    AuthError: 'Falscher Bestätigungscode',
    crmError: `Bei der Anmeldung ist ein unerwarteter Fehler aufgetreten.
       Bitte kontaktieren Sie Niko, um das Problem zu lösen.`,
    invalidPassword: 'Ihr Passwort ist abgelaufen, Sie haben eine E-Mail mit weiteren Anweisungen erhalten.',
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'Die E-Mails müssen übereinstimmen',
      newEmailBasic: 'Ungültiges Feld	',
      newEmailWrongFormat: 'Kein gültiges E-Mail-Format',
      newEmailUserNotFound: 'Sie müssen eingeloggt sein, um Ihre E-Mail zu aktualisieren.',
    },
  },
};
