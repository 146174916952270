/* React */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import './index.css';
import WebFontLoader from 'webfontloader';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Amplify } from '@aws-amplify/core';
import { Typography } from '@material-ui/core';
import Entry from './Entry';
import awsExports from './aws-exports-oauth';
import { UserProvider } from './hooks/UserContext';
import { TabsProvider } from './hooks/TabsContext';
import { LocaleProvider } from './i18n/LocaleContext';
import { RedirectProvider } from './hooks/RedirectContext';
import reportWebVitals from './reportWebVitals';

WebFontLoader.load({
  google: {
    families: ['Roboto:400,700', 'Material Icons'],
  },
});

/* Bug Tracking */
Sentry.init({
  dsn: 'https://faf314a5fd90434d96b9a03ce00c3293@sentry.io/1858112', //
});

/* Style */
export const theme = createTheme({
  //
  palette: {
    common: { black: 'rgba(51, 51, 51, 1)', white: 'rgba(77, 77, 77, 1)' },
    background: { paper: 'rgba(255, 255, 255, 1)', default: 'rgba(242, 244, 247, 1)' },
    primary: {
      main: 'rgba(26, 26, 26, 1)',
      dark: 'rgba(60,60,60,1)',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgba(254, 209, 59, 1)',
      dark: 'rgba(229, 178, 34, 1)',
      contrastText: 'rgba(51, 51, 51, 1)',
    },
    error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(255, 255, 255, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Roboto',
    fontSize: 14,
    // fontWeightLight: 300,
    fontWeightRegular: 400,
    // fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '16px',
      // letterSpacing: 1.6,
      lineHeight: '28px',
      color: '#1A1A1A',
      fontStyle: 'normal',
    },
    h2: {
      color: '#F6F6F6',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14,

      lineHeight: '24px',
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      color: '#4C4C4C',
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      color: '#1A1A1A',
    },
    h5: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: '16px',
      letterSpacing: '1.2px',
      lineHeight: '18px',
      color: '#808080',
    },
    h6: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '21px',
      color: '#222222',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '21px',
      color: '#000000',
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#000000',
    },
  },
  shape: {
    borderRadius: 46,
  },
  overrides: {
    MuiSelect: {
      select: {
        '&$focus': {
          borderRadius: 46,
        },
      },
      root: {
        '&$focus': {
          borderWidth: 0,
        },
        borderWidth: 0,
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#1A1A1A',
      },
    },

    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: 0,
        },
      },
      input: {
        paddingLeft: 24,
      },
      notchedOutline: {
        borderWidth: 0,
      },
    },
    MuiLink: {
      button: {
        '&$focusVisible': {
          outline: 'None',
        },
      },
    },
  },
});

Amplify.configure({
  ...awsExports,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary fallback={() => <Typography>An error has occured</Typography>} showDialog>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LocaleProvider>
          <UserProvider>
            <RedirectProvider>
              <TabsProvider>
                <Entry />
              </TabsProvider>
            </RedirectProvider>
          </UserProvider>
        </LocaleProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Sentry.ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
