/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { makeStyles, Container, CssBaseline, Link, FormControl, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import * as Yup from 'yup';

import { useLocale } from 'i18n/LocaleContext';
import CancelButton from 'components/Buttons/CancelButton';
import LoaderButton from 'components/Buttons/LoaderButton';
import { AuthError, useUser } from 'hooks/UserContext';
import useTouch from 'hooks/useTouch';
import { useRedirect } from 'hooks/RedirectContext';
import { CognitoUser } from '@aws-amplify/auth';
import { ReactComponent as CheckBoxIcon } from '../../../img/checkedYellow.svg';
import { ReactComponent as CheckBoxOutlineBlankIcon } from '../../../img/checkblank.svg';
import { ReactComponent as PasswordNoSee } from '../../../img/passwordnosee.svg';
import { ReactComponent as PasswordSee } from '../../../img/passwordsee.svg';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: 'relative',
    left: -7.5,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  smallButton: {
    [theme.breakpoints.down(450)]: {
      width: 150,
    },
    height: 50,
    width: 184,
    background: '#F2F2F2',
    '&:hover': {
      backgroundColor: '#D9D9D9',
    },
  },
  socialMediaButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 48,
  },
  tabWrapper: {
    margin: 0,
    padding: 0,
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  line: {
    [theme.breakpoints.down(450)]: {
      width: 55,
    },
    width: 122,
  },
  errorText: {
    padding: 0,
    margin: 0,
    marginTop: 15,
  },
  adornedNoPadding: {
    paddingRight: 0,
  },
}));

interface FormLoginValues {
  email: string;
  password: string;
  defaultErrorMessage?: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().min(1).required(),
  password: Yup.string().min(1).required(),
});

const Login: React.FC = () => {
  const { formatMessage } = useIntl();
  const { login, setTempUser, checkPasswordHashUrl, tempUser } = useUser();
  const { getRefererUri, getRedirectUri } = useRedirect();
  const { langAndCountryFormated } = useLocale();
  const history = useHistory();

  const classes = useStyles();
  const [isTouched, touchEventListeners] = useTouch();

  const errorUserNotFoundHandler = async (
    email: string,
    password: string,
    setErrors: (errors: FormikErrors<FormLoginValues>) => void,
  ) => {
    const response = await checkPasswordHashUrl(email, password);
    const {
      result: {
        crm_user_auth_success: crmAuthSuccess,
        cog_user_creation_success: createdInCognito,
        cog_user_confirmation_success: confirmedInCognito,
        error: checkPasswordHashUrlError,
      },
    } = response;
    // error when the user has an invalid password
    if (checkPasswordHashUrlError === 'User cannot be confirmed. Current status is FORCE_CHANGE_PASSWORD') {
      setErrors({
        password: checkPasswordHashUrlError,
        defaultErrorMessage: formatMessage({ id: 'error.invalidPassword' }),
      });
      return;
    }
    // generic error from CRM
    if (typeof checkPasswordHashUrlError !== 'undefined' && checkPasswordHashUrlError !== null) {
      setErrors({
        password: checkPasswordHashUrlError,
        defaultErrorMessage: formatMessage({ id: 'error.crmError' }),
      });
      return;
    }

    // user was created no problem.
    if (crmAuthSuccess && createdInCognito && confirmedInCognito) {
      try {
        await login(email, password);
      } catch (error) {
        const confirmError = error as AuthError;
        setErrors({ password: confirmError.name, defaultErrorMessage: confirmError.message });
      }
      return;
    }
    // user was created but not confirmed.
    if (crmAuthSuccess && createdInCognito) {
      setTempUser({ username: email, password });
      history.push(`/${langAndCountryFormated}/confirm`);
    }
    // otherwise
    setErrors({ password: 'NotAuthorizedException', defaultErrorMessage: 'Incorrect username or password' });
  };

  const errorSubmitHandler = async (
    err: AuthError,
    email: string,
    password: string,
    setErrors: (errors: FormikErrors<FormLoginValues>) => void,
  ) => {
    // eslint-disable-next-line no-console
    console.log({ err });
    if (err.code === 'UserNotConfirmedException') {
      setTempUser({ username: email, password });
      history.push(`/${langAndCountryFormated}/confirm`);
      return;
    }
    if (err.name === 'PasswordResetRequiredException') {
      setTempUser({ username: email, password });
      history.push(`/${langAndCountryFormated}/reset`);
      return;
    }
    if (err.name === 'UserNotFoundException') {
      await errorUserNotFoundHandler(email, password, setErrors);
      return;
    }
    setErrors({ password: err.name, defaultErrorMessage: err.message });
  };

  const handleSubmit = async (
    values: FormLoginValues,
    { setSubmitting, setErrors }: FormikHelpers<FormLoginValues>,
  ) => {
    const processedEmail = values.email.includes('@') ? values.email.trim().toLowerCase() : values.email;
    try {
      const user: CognitoUser = await login(processedEmail, values.password);
      user?.getUserAttributes((err, attributes) => {
        if (attributes?.find(attribute => attribute.Name === 'email_verified')?.Value === 'false') {
          setTempUser({ username: processedEmail, password: values.password });
          history.push(`/${langAndCountryFormated}/confirm`);
        }
      });
    } catch (e) {
      const err = e as AuthError;
      await errorSubmitHandler(err, processedEmail, values.password, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    const refererUri = getRefererUri();
    const redirectUri = getRedirectUri();
    // eslint-disable-next-line no-console
    console.log(getRefererUri());
    if (refererUri) {
      window.location.replace(refererUri);
      return;
    }
    if (redirectUri) {
      window.location.replace(redirectUri);
      return;
    }
    history.goBack();
  };

  return (
    <Formik
      initialValues={
        {
          email:
            tempUser !== null &&
            typeof tempUser !== 'undefined' &&
            tempUser.username !== null &&
            typeof tempUser.username !== 'undefined'
              ? tempUser.username
              : '',
          password: '',
          defaultErrorMessage: undefined,
        } as FormLoginValues
      }
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Container className={classes.tabWrapper} maxWidth={false}>
          <CssBaseline />
          <div className={classes.paper}>
            {/* <Typography variant="h1">{formatMessage({ id: 'login.connectUsing' })}</Typography> */}

            {/* <div className={classes.socialMediaButton}>
              <Button
                variant="contained"
                className={classes.smallButton}
                style={{ marginRight: '16px' }}
                onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })}
              >
                <img src={fcbImg} alt="Facebook" />
              </Button>
              <Button variant="contained" className={classes.smallButton} onClick={() => 
                Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>
                <img src={googleImg} alt="Google" />
              </Button>
            </div> */}

            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              <Divider className={classes.line} />

              <Typography align="center" style={{ marginLeft: 16, marginRight: 16 }} variant="h5">
                {formatMessage({ id: 'login.withEmail' })}
              </Typography>

              <Divider className={classes.line} />
            </div> */}

            <Form className={classes.form}>
              {/* <FormControl style={{ marginTop: 32 }} fullWidth> */}
              <FormControl
                fullWidth
                style={{
                  borderLeft:
                    (errors.email && touched.email) || errors.password === 'NotAuthorizedException'
                      ? '3px solid #EA1E27'
                      : '3px solid transparent',
                  paddingLeft: 15,
                }}
              >
                <Typography variant="h3">{formatMessage({ id: 'email' })}</Typography>
                <TextField
                  id="email"
                  type="text"
                  name="email"
                  autoComplete="email"
                  margin="normal"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    classes: {
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  autoFocus
                  fullWidth
                  error={Boolean(errors.email && touched.email)}
                />
              </FormControl>

              <FormControl
                style={{
                  marginTop: 24,
                  borderLeft: errors.password && touched.password ? '3px solid #EA1E27' : '3px solid transparent',
                  paddingLeft: 15,
                }}
                fullWidth
              >
                <Typography variant="h3" style={{ marginBottom: 12 }}>
                  <div style={{ display: 'flex', alignContent: 'center' }}>
                    <p style={{ display: 'inline', width: '65%', margin: '1px 8px 0px 0px' }}>
                      {formatMessage({ id: 'login.password' })}{' '}
                    </p>
                    <Link
                      style={{ width: '35%', float: 'right', cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/${langAndCountryFormated}/reset`);
                      }}
                      color="primary"
                    >
                      <Typography variant="h3" style={{ float: 'right', textDecoration: 'underline' }}>
                        {formatMessage({ id: 'login.forgot' })}
                      </Typography>
                    </Link>
                  </div>
                </Typography>
                <TextField
                  id="password"
                  variant="outlined"
                  type={isTouched ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" {...touchEventListeners}>
                        <Button style={{ background: 'transparent', textTransform: 'none' }} disableRipple>
                          {isTouched ? <PasswordNoSee /> : <PasswordSee />}
                        </Button>
                      </InputAdornment>
                    ),
                    classes: {
                      adornedEnd: classes.adornedNoPadding,
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorText,
                    },
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors.password && touched.password)}
                  helperText={
                    errors.password &&
                    errors.defaultErrorMessage &&
                    formatMessage({
                      id: `error.${errors.password}`,
                      defaultMessage: errors.defaultErrorMessage,
                    })
                  }
                />
              </FormControl>

              <FormControlLabel
                style={{ marginTop: 12, paddingLeft: 15 }}
                control={
                  <Checkbox
                    style={{ color: '#D9D9CF' }}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    value="checkedI"
                  />
                }
                label={<Typography variant="h4">{formatMessage({ id: 'login.remember' })}</Typography>}
              />
              <div style={{ paddingLeft: 15 }}>
                <CancelButton onClick={handleCancelClick} label={formatMessage({ id: 'back' })} />
                <LoaderButton loading={isSubmitting} success={status}>
                  <Typography variant="h2">{formatMessage({ id: 'login.connect' })}</Typography>
                </LoaderButton>
              </div>
            </Form>
          </div>
        </Container>
      )}
    </Formik>
  );
};

export default Login;
