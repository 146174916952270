/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import useDeviceDetect from './useDeviceDetect';

const preventDefault = (event: React.TouchEvent) => {
  if (event.preventDefault) {
    event.preventDefault();
  }
};

const useTouch = (): [
  boolean,
  {
    onTouchStart?: (e: React.TouchEvent) => void;
    onTouchEnd?: (e: React.TouchEvent) => void;
    onMouseDown?: (e: React.MouseEvent) => void;
    onMouseUp?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
  },
] => {
  const { isMobile } = useDeviceDetect();
  const timeout = useRef<NodeJS.Timeout>();
  const target = useRef<EventTarget>();
  const [isTouched, setTouched] = useState<boolean>(false);

  const startTouchEvent = useCallback((event: React.TouchEvent) => {
    setTouched(true);
    if (event.target) {
      event.target.addEventListener('touchend touchcancel', preventDefault as unknown as EventListener, {
        passive: false,
      });
      target.current = event.target;
    }
    timeout.current = setTimeout(() => {
      setTouched(false);
    }, 2000);
  }, []);

  const stopToucheEvent = useCallback((_event: React.TouchEvent) => {
    if (timeout.current) clearTimeout(timeout.current);
    if (target.current) {
      target.current.removeEventListener('touchend touchcancel', preventDefault as unknown as EventListener);
    }
    setTouched(false);
  }, []);

  const touchEventListeners = useMemo(
    () =>
      isMobile
        ? {
            onTouchStart: (e: React.TouchEvent) => startTouchEvent(e),
            onTouchEnd: (e: React.TouchEvent) => stopToucheEvent(e),
          }
        : {
            onMouseDown: (_e: React.MouseEvent) => setTouched(true),
            onMouseUp: (_e: React.MouseEvent) => setTouched(false),
            onMouseLeave: (_e: React.MouseEvent) => setTouched(false),
          },
    [isMobile, startTouchEvent, stopToucheEvent],
  );

  return [isTouched, touchEventListeners];
};

export default useTouch;
