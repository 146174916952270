import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { useIntl } from 'react-intl';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { Theme, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { makeStyles } from '@material-ui/core/styles';

import { useLocale } from 'i18n/LocaleContext';
import { appBarHeight } from 'App';
import MailSignUpForm from './MailSignupForm';

const useStyles = makeStyles((theme: Theme) => ({
  mailSignupTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },
  backBar: {
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(242, 242, 242, 1)',
    },
    height: 54,
    background: 'rgba(77, 77, 79, 1)',
  },
  backToolBar: {
    height: 54,
    verticalAlign: 'center',
    padding: 0,
  },
  backArrow: {
    [theme.breakpoints.down('xs')]: {
      color: 'rgba(41, 41, 41, 1)',
    },
    marginLeft: 24,
    marginRight: 16,
    width: 21,
    color: 'rgba(255, 255, 255, 1)',
  },
  backText: {
    [theme.breakpoints.down('xs')]: {
      color: 'rgba(41, 41, 41, 1)',
    },
    color: 'rgba(255, 255, 255, 1)',
  },
  mailSignUpBody: {
    paddingTop: 40,
    padding: '48px 48px 48px 33px',
  },
}));

const MailSignUp: React.FC<RouteChildrenProps> = props => {
  const { history } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { langAndCountryFormated } = useLocale();

  return (
    <Container className={classes.mailSignupTab}>
      <AppBar position="relative" className={classes.backBar}>
        <Toolbar variant="dense" className={classes.backToolBar}>
          <Button
            style={{ background: 'transparent' }}
            onClick={() => {
              history.push(`/${langAndCountryFormated}/signUp`);
            }}
            disableRipple
          >
            <ArrowBackIcon className={classes.backArrow} />
          </Button>
          <Typography variant="h3" className={classes.backText}>
            {formatMessage({ id: 'back' })}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.mailSignUpBody}>
        <MailSignUpForm {...props} />
      </Container>
    </Container>
  );
};

export default MailSignUp;
