export default {
  send: 'Preprava',
  email: 'E-mailové adresy',
  back: 'Späť',
  signWithEmail: 'Prihláste sa',
  tabLogin: 'Prihlásenie',
  tabSign: 'Registrácia',
  newPassword: 'Nové heslo',
  newEmailTextField: 'Nový e-mail',
  confirmNewEmailTextField: 'Potvrdenie nového e-mailu',

  confirm: {
    confirm: 'Potvrdiť',
    finish: 'Dokončiť',
    code: 'Overovací kód',
    title: 'Vyplňte overovací kód',
    text1: 'Práve sme vám poslali e-mail.',
    text2: 'Skontrolujte, prosím, svoju poštovú schránku a postupujte podľa pokynov na overenie svojho účtu.',
    text3: 'Upozorňujeme, že e-mail sa môže nachádzať v priečinku s nevyžiadanou poštou.',
    required: 'Overovací kód je povinné pole',
    resendCode: 'Opätovné odoslanie kódu',
    resendCodePrompt: 'Bol odoslaný nový overovací kód, skontrolujte si, prosím, svoju doručenú poštu.',
  },

  newpassword: {
    title: 'Zadajte svoje nové heslo',
    minRequirements: 'Minimálne požiadavky: 1 veľké písmeno, 1 malé písmeno, 1 číslo.',
  },

  button: {
    cancel: 'Zrušiť',
    cancelRequest: 'Zrušiť žiadosť',
    continue: 'Pokračovať',
    next: 'Ďalšie',
    finish: 'Dokončiť',
  },

  login: {
    password: 'Heslo',
    connect: 'Prihlásenie',
    connectUsing: 'Prihláste sa pomocou',
    withEmail: 'alebo pomocou e-mailu',
    passwordHelp: 'Min: 8 znakov vrátane 1 veľkého písmena, 1 malého písmena a 1 čísla',
    logout: 'Odhlásenie',
    remember: 'Zapamätať si moje údaje',
    forgot: 'Zabudol som heslod',
  },

  signup: {
    with: 'Zaregistrujte sa ',
    or: 'alebo',
  },

  reset: {
    resetPassword: 'Zabudli ste svoje heslo?',
    resetPasswordText: `Zadajte e-mailovú adresu priradenú k vášmu kontu.  
    Pošleme vám overovací kód na obnovenie hesla.`,
    temporaryPassword: 'Skontrolujte si, či sa vo vašej poštovej schránke nenachádza dočasné heslo',
    confirmResetPasswordText1: 'Overovací kód bol odoslaný na adresu:',
    confirmResetPasswordText2: 'Skontrolujte svoju poštovú schránku a postupujte podľa pokynov na obnovenie hesla.',
    confirmResetPasswordText3: 'Upozorňujeme, že e-mail sa môže nachádzať v priečinku s nevyžiadanou poštou',
    goToLogin: 'Vráťte sa späť na prihlásenie',
  },

  mailSignup: {
    signUp: 'Registrácia',
    firstName: 'Krstné meno',
    lastName: 'Priezvisko',
    choosePassword: 'Heslo',
    hide: 'Skryť',
    show: 'Zobraziť',
    passwordHelp: 'Min: 8 znakov, 1 veľké a malé písmeno a 1 číslo',
    country: 'Krajina',
    language: 'Jazyk',
    receiveInfo: 'Chcem dostávať informácie o spoločnosti Niko',
    textPrivacyPolicy:
      'Odoslaním tohto formulára súhlasíte s tým, že spoločnosť Niko môže spracúvať vaše osobné údaje podľa našich ',
    privacyPolicy: 'zásadami ochrany osobných údajov',
    termsOfUse1: 'Rozumiem a akceptujem',
    termsOfUse2: 'podmienky používania',
    mandatoryFields: 'Povinné polia',
    dataTreatment: ' jej zásady ochrany osobných údajov',
  },

  language: {
    french: 'Francúzština',
    english: 'Angličtina',
    swedish: 'Švédčina',
    danish: 'Dánčina',
    slovak: 'Slovensky',
    dutch: 'Holandský',
    german: 'Nemčina',
  },

  country: {
    belgium: 'Belgicko',
    denmark: 'Dánsko',
    france: 'Francúzsko',
    sweden: 'Švédsko',
    slovakia: 'Slovensko',
    germany: 'Nemecko',
    netherland: 'Holandsko',
  },

  error: {
    required: 'Požadované pole',
    apicall: 'Niečo sa pokazilo na serveri api!',
    UserNotFoundException: 'Používateľ neexistuje.',
    NotAuthorizedException: 'Nesprávne používateľské meno alebo heslo.',
    NotAuthorizedExceptionReset: 'Nesprávne používateľské meno.',
    UsernameExistsException: 'Účet so zadaným e-mailom už existuje.',
    InvalidParameterExceptionPassword: `Heslo nebolo v súlade so zásadami: 
    Heslo musí mať dĺžku väčšiu alebo rovnú 8, obsahuje 1 veľké a malé písmeno, 1 číslo.`,
    InvalidPasswordExceptionLong: 'Heslo nebolo v súlade so zásadami: Heslo nie je dostatočne dlhé.',
    InvalidPasswordExceptionLower: 'Heslo nebolo v súlade so zásadami: Heslo musí obsahovať malé písmená.',
    InvalidPasswordExceptionUpper: 'Heslo nebolo v súlade so zásadami: Heslo musí obsahovať malé písmená.',
    InvalidPasswordExceptionNumeric: 'Heslo nebolo v súlade so zásadami: Heslo musí obsahovať číselné znaky.',
    CodeMismatchException: 'Zadaný neplatný overovací kód, skúste to prosím znova.',
    email: 'Chýba',
    confirmationCode: 'Chýba',
    AuthError: 'Nesprávny potvrdzovací kód',
    crmError: 'Počas prihlasovania došlo k neočakávanej chybe, kontaktujte spoločnosť Niko, aby ste problém vyriešili.',
    invalidPassword: 'Platnosť vášho hesla vypršala, bol vám zaslaný e-mail s ďalšími pokynmi.',
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'E-maily sa musia zhodovať',
      newEmailBasic: 'Nesprávne pole',
      newEmailWrongFormat: 'Nie je platný formát e-mailu',
      newEmailUserNotFound: 'Na aktualizáciu e-mailu musíte byť prihlásení.',
    },
  },
};
