import React, { useEffect } from 'react';

import { RouteChildrenProps } from 'react-router';
import { Container } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useUser } from '../../../hooks/UserContext';

import {
  useRedirect,
  removeRedirectUriFromCache,
  isRedirectUriValid,
  getRedirectUriWithParams,
} from '../../../hooks/RedirectContext';
import { GET_EXCEPTION_LIST } from '../../../sitecore';
import ExitCard from './ExitCard';

interface ExitProps extends RouteChildrenProps {
  cleanup?: () => void;
}

const Exit: React.FC<ExitProps> = ({ cleanup }) => {
  const { user } = useUser();
  const { getRedirectUri } = useRedirect();
  const { formatMessage } = useIntl();

  useEffect(() => {
    // if the function must do something before redirecting (e.g. logging out)
    if (cleanup) cleanup();
    const redirectUri = getRedirectUri();

    if (!user) {
      console.error('Missing User');
      return;
    }

    // if redirectUrl not valid or undefined, nothing happens.
    if (redirectUri === null || !isRedirectUriValid(redirectUri)) {
      console.error('Redirect URL not valid');
      return;
    }

    // if redirectUrl part of the exception list, we perform a GET instead
    if (user && GET_EXCEPTION_LIST.includes(redirectUri)) {
      removeRedirectUriFromCache();
      window.location.replace(getRedirectUriWithParams(user, redirectUri));
    }
    // Otherwise, we use the form to perform a POST request
    removeRedirectUriFromCache();
    const form = document.getElementById('redirectForm') as HTMLFormElement;
    form.action = redirectUri;
    fillInForm();
    form.submit();
  }, []);

  const fillInForm = () => {
    const idTokenField = document.getElementById('idTokenFormField') as HTMLInputElement;
    const accessTokenField = document.getElementById('accessTokenFormField') as HTMLInputElement;
    const refreshTokenField = document.getElementById('refreshTokenFormField') as HTMLInputElement;
    idTokenField.value = user?.getSignInUserSession()?.getIdToken().getJwtToken() ?? 'Missing';
    accessTokenField.value = user?.getSignInUserSession()?.getAccessToken().getJwtToken() ?? 'Missing';
    refreshTokenField.value = user?.getSignInUserSession()?.getRefreshToken().getToken() ?? 'Missing';
  };

  return (
    <Container>
      <ExitCard title={formatMessage({ id: 'exit.loginTitle' })} />
      <form method="post" id="redirectForm" hidden>
        <input type="text" id="idTokenFormField" name="id_token" value="" readOnly />
        <input type="text" id="accessTokenFormField" name="access_token" value="" readOnly />
        <input type="text" id="refreshTokenFormField" name="refresh_token" value="" readOnly />
      </form>
    </Container>
  );
};

export default Exit;
