export const parseInvalidPasswordException = (e: Error) => {
  if (e.message.indexOf('Password not long') !== -1) return 'InvalidPasswordExceptionLong';
  if (e.message.indexOf('Password must have upper') !== -1) return 'InvalidPasswordExceptionUpper';
  if (e.message.indexOf('Password must have lower') !== -1) return 'InvalidPasswordExceptionLower';
  if (e.message.indexOf('Password must have numer') !== -1) return 'InvalidPasswordExceptionNumeric';
  return e.message;
};

export const parseInvalidParameterException = (e: Error) => {
  if (e.message.indexOf("Value at 'password' failed") !== -1) return 'InvalidParameterExceptionPassword';
  return e.message;
};
