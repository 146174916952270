import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  buttonRoot: {
    color: '#F6F6F6',
    height: '50px',
    marginTop: 22,
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  },
  buttonSuccess: {
    height: '50px',
    marginTop: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 0,
    marginLeft: -12,
  },
}));

interface ResendCodeButtonTypes extends ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const ResendCodeButton: React.FC<ResendCodeButtonTypes> = ({ onClick = (): void => {}, disabled = false, ...rest }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.buttonRoot}
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        fullWidth
        {...rest}
      >
        {formatMessage({ id: 'confirm.resendCode' })}
      </Button>
    </div>
  );
};

export default ResendCodeButton;
