/* eslint-disable no-redeclare */
import React, { useContext, useState } from 'react';

import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deDateLocale from 'date-fns/locale/de';
import dkDateLocale from 'date-fns/locale/da';
import enDateLocale from 'date-fns/locale/en-US';
import frDateLocale from 'date-fns/locale/fr';
import nlDateLocale from 'date-fns/locale/nl';
import seDateLocale from 'date-fns/locale/sv';
import skDateLocale from 'date-fns/locale/sk';
import { flattenMessages } from './utils';

import deLocale from './messages_de';
import dkLocale from './messages_dk';
import enLocale from './messages_en';
import frLocale from './messages_fr';
import nlLocale from './messages_nl';
import seLocale from './messages_se';
import skLocale from './messages_sk';

export interface LocaleContext {
  language: string;
  country: string;
  setCountry: (country: string) => void;
  setLanguage: (language: string) => void;
  updateLanguage: (lang: string) => void;
  updateCountry: (country: string) => void;
  langAndCountryFormated: string;
}

export const LocaleContext = React.createContext<LocaleContext | null>(null);

const dateLocaleMap = {
  da: dkDateLocale,
  de: deDateLocale,
  en: enDateLocale,
  fr: frDateLocale,
  nl: nlDateLocale,
  sv: seDateLocale,
  sk: skDateLocale,
};

const legalCountryCode: string[] = ['FR', 'DE', 'SE', 'DK', 'BE', 'NL', 'SK'];

const providerLocaleMap: Record<string, any> = {
  da: dkLocale,
  de: deLocale,
  en: enLocale,
  fr: frLocale,
  nl: nlLocale,
  sv: seLocale,
  sk: skLocale,
};

export const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
  const initLanguage = 'en';
  const initCountry = '';

  const [language, setLanguage] = useState<string>(initLanguage);
  const [country, setCountry] = useState<string>(initCountry);

  const updateLanguage = (lang: string) => {
    if (lang in providerLocaleMap) {
      setLanguage(lang);
    }
  };

  const updateCountry = (newCountry: string) => {
    if (legalCountryCode.includes(newCountry != null ? newCountry.toUpperCase() : '')) {
      setCountry(newCountry);
    }
  };

  const langAndCountryFormated = country !== '' ? `${language}-${country}` : language;

  const values = React.useMemo(
    () => ({
      language,
      setLanguage,
      country,
      setCountry,
      updateLanguage,
      updateCountry,
      langAndCountryFormated,
    }),
    [language, country, langAndCountryFormated],
  );

  return (
    <LocaleContext.Provider value={values}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={(dateLocaleMap as any)[language]}>
        <IntlProvider
          key={language}
          locale={language}
          messages={flattenMessages(providerLocaleMap[language])}
          defaultLocale={language}
        >
          {children}
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = (): LocaleContext => {
  const context = useContext<LocaleContext | null>(LocaleContext);

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context as LocaleContext;
};
