import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import App from './App';

import { useRedirect } from './hooks/RedirectContext';

interface QS {
  client_id?: string;
  redirect_uri?: string;
  response_mode?: string;
  response_type?: string;
  scope?: string;
  state?: string;
  nonce?: string;
}

// const PATH = '/msportal';

function getQueryVariables(qs: string): QS {
  const queryParams = qs.substring(1);
  const paramsList: Record<string, string> = {};
  const vars = queryParams.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair.length === 2) {
      // eslint-disable-next-line prefer-destructuring
      paramsList[pair[0]] = pair[1];
    }
  }

  return paramsList;
}

const Entry: React.FC = () => {
  const location = useLocation();
  const { setRefererUri, setRedirectUri } = useRedirect();

  useEffect(() => {
    const qs: QS = getQueryVariables(location.search);
    if (qs !== null && qs.redirect_uri !== null && typeof qs.redirect_uri !== 'undefined') {
      setRedirectUri(decodeURIComponent(qs.redirect_uri));
    }
  }, [location.search]);

  useEffect(() => {
    setRefererUri(document.referrer);
  }, []);

  return <App />;
};

export default Entry;
