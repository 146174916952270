import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router';

import { useUser } from 'hooks/UserContext';
import Tabs from 'components/Tabs/Tabs';
import Confirm from 'pages/Unauthenticated/SignUpConfirm/Confirm';
import ChangeEmail from 'pages/Authenticated/ChangeEmail/ChangeEmail';
import ChangeEmailConfirm from 'pages/Authenticated/ChangeEmail/ChangeEmailConfirm';
import Exit from 'pages/Authenticated/Exit/Exit';
import Logout from 'pages/Authenticated/Logout/Logout';
import MailSignUp from 'pages/Unauthenticated/SignUp/MailSignUp';
import CrmForgotPassword from 'pages/Unauthenticated/ForgotPassword/CrmForgotPassword/CrmForgotPassword';
import Reset from 'pages/Unauthenticated/ForgotPassword/ForgotPassword';
import ChangePassword from 'pages/Authenticated/ChangePassword/ChangePassword';

export const UnauthenticatedRoutes: React.FC = () => {
  const { tempUser } = useUser();
  const [, updateTab] = useState<number>(0);
  return (
    <Switch>
      {tempUser && <Route path={['/confirm', '/:lang/confirm']} exact render={props => <Confirm {...props} />} />}
      {tempUser && <Route path={['/reset', '/:lang/reset']} exact render={() => <Reset email={tempUser.username} />} />}
      <Route path={['/mailSignUp', '/:lang/mailSignUp']} exact render={props => <MailSignUp {...props} />} />
      <Route path={['/reset', '/:lang/reset']} exact render={() => <Reset />} />
      <Route
        path={['/signUp', '/:lang/signUp']}
        exact
        render={props => <Tabs updateTab={updateTab} initialTab={1} {...props} />}
      />
      <Route path={['/logout', '/:lang/logout']} render={() => <Logout />} />
      <Route
        path={['/login', '/:lang/login', '/:lang', '/']}
        render={props => <Tabs updateTab={updateTab} initialTab={0} {...props} />}
      />
    </Switch>
  );
};

export const AuthenticatedRoutes: React.FC = () => {
  const { tempUser, user, changeEmailStatus, changePasswordChallenge } = useUser();
  const [emailVerified, setEmailVerified] = useState<boolean>(false);

  useEffect(() => {
    user?.getUserAttributes((err, attributes) => {
      setEmailVerified(attributes?.find(attribute => attribute.Name === 'email_verified')?.Value === 'true');
    });
  }, [user]);

  if (changeEmailStatus && !changeEmailStatus?.isConfirmed) {
    return (
      <Switch>
        <Route path={['/exit', '/:lang/exit']} exact render={props => <Exit {...props} />} />
        <Route path={['*']} render={props => <ChangeEmailConfirm {...props} />} />
      </Switch>
    );
  }
  if (changePasswordChallenge) {
    return (
      <Switch>
        <Route path={['*']} render={() => <CrmForgotPassword />} />
      </Switch>
    );
  }
  return (
    <Switch>
      {tempUser && !emailVerified && (
        <Route path={['/confirm', '/:lang/confirm']} exact render={props => <Confirm {...props} />} />
      )}
      <Route path={['/reset', '/:lang/reset']} exact render={() => <Reset />} />
      <Route path={['/changeEmail', '/:lang/changeEmail']} exact render={props => <ChangeEmail {...props} />} />
      <Route
        path={['/changePassword', '/:lang/changePassword']}
        exact
        render={props => <ChangePassword {...props} />}
      />
      <Route path={['/logout', '/:lang/logout']} render={() => <Logout />} />
      <Route path={['*']} render={props => <Exit {...props} />} />
    </Switch>
  );
};
