/* eslint-disable no-redeclare */
import React, { useState, useContext } from 'react';

export interface TabsContext {
  showTabs: boolean;
  setShowTabs: (show: boolean) => void;
}
// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
export const TabsContext = React.createContext<TabsContext | null>(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const TabsProvider = ({ children }: { children: React.ReactNode }) => {
  const [showTabs, setShowTabs] = useState<boolean>(true);

  const values = React.useMemo(
    () => ({
      showTabs,
      setShowTabs,
    }),
    [showTabs],
  );

  // Finally, return the interface that we want to expose to our other components
  return <TabsContext.Provider value={values}>{children}</TabsContext.Provider>;
};

export const useTabs = (): TabsContext => {
  const context = useContext<TabsContext | null>(TabsContext);

  if (context === undefined) {
    throw new Error('`useTabs` hook must be used within a `TabsProvider` component');
  }
  return context as TabsContext;
};
