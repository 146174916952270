import React from 'react';
import { useIntl } from 'react-intl';

import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';
import { useLocale } from 'i18n/LocaleContext';
import Login from 'pages/Unauthenticated/Login/Login';
import MailSignUpForm from 'pages/Unauthenticated/SignUp/MailSignupForm';
import { TabProps, tabsMatchingReverse } from 'App';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <Typography component="div" hidden={value !== index}>
      <Box style={{ padding: 48 }}>{children}</Box>
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  indicator: {
    height: 4,
    // [theme.breakpoints.down('xs')]: {
    //   height: 0,
    // },
  },
  tabs: {
    // [theme.breakpoints.down('xs')]: {
    //   background: theme.palette.primary.main,
    // },
  },
  selectedTab: {
    // [theme.breakpoints.down('xs')]: {
    //   background: 'white',
    //   borderWidth: 0,
    // },
    borderBottom: '4px solid #1A1A1A',
    color: '#1A1A1A',
    fontFamily: 'Arial, Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'none',
    lineHeight: '17px',
  },
  unselectedTab: {
    // [theme.breakpoints.down('xs')]: {
    //   color: '#7F7F7F',
    //   borderWidth: 0,
    // },
    fontFamily: 'Arial, Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '17px',
    textTransform: 'none',
    color: '#7F7F7F',
    borderBottom: '1px solid #B2B2B2',
  },
}));

const NikoTabs: React.FC<TabProps & RouteComponentProps> = props => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { langAndCountryFormated } = useLocale();

  const { initialTab: tab, updateTab, history } = props;
  const handleTabChange = (_event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    updateTab(newValue);
    history.push(`/${langAndCountryFormated}/${tabsMatchingReverse[newValue]}`);
  };

  return (
    <Container style={{ margin: 0, padding: 0 }}>
      <Tabs
        value={tab}
        centered
        indicatorColor="primary"
        variant="fullWidth"
        onChange={handleTabChange}
        className={classes.tabs}
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={formatMessage({ id: 'tabLogin' })}
          className={tab === 0 ? classes.selectedTab : classes.unselectedTab}
        />
        <Tab
          label={formatMessage({ id: 'tabSign' })}
          className={tab === 1 ? classes.selectedTab : classes.unselectedTab}
        />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <Login />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {/* <SignUp {...props} /> */}
        <MailSignUpForm {...props} />
      </TabPanel>
    </Container>
  );
};

export default NikoTabs;
