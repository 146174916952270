export default {
  send: 'Envoyer',
  email: 'E-mail',
  back: 'Retour',
  signWithEmail: 'Inscrivez-vous avec e-mail',
  tabLogin: 'Login',
  tabSign: 'Enregistrer',
  newPassword: 'Nouveau mot de passe',
  newEmailTextField: 'Nouveau e-mail',
  confirmNewEmailTextField: 'Confirmer le nouveau e-mail	',

  confirm: {
    confirm: 'Confirmer',
    finish: 'finaliser ',
    code: 'Code de vérification',
    title: 'Veuillez remplir le code de vérification',
    text1: 'Nous venons de vous envoyer un e-mail.',
    text2: 'Veuillez consulter votre boîte aux lettres et suivre les instructions pour vérifier votre compte.',
    text3: "Notez que l'e-mail peut se trouver dans votre dossier spam.",
    required: 'Le code de vérification est un champ obligatoire',
    resendCode: 'Renvoyer le code',
    resendCodePrompt: 'Un nouveau code de vérification a été envoyé, veuillez vérifier votre boîte de réception.	',
  },

  newpassword: {
    title: 'Veuillez saisir votre nouveau mot de passe',
    minRequirements: 'Exigences minimales : 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre',
  },

  button: {
    cancel: 'Annuler',
    cancelRequest: 'Annuler la demande',
    continue: 'Continuer',
    next: 'Suivant',
    finish: 'Terminer ',
  },

  login: {
    password: 'Mot de passe',
    connect: 'Se connecter',
    connectUsing: 'Se connecter en utilisant ',
    withEmail: "ou à l'adresse électronique",
    passwordHelp: 'Exigences minimales : 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre',
    logout: 'Déconnexion',
    remember: 'Mémoriser mes informations',
    forgot: 'Mot de passe oublié',
  },

  signup: {
    with: 'Sinscrire auprès de',
    or: 'ou',
  },

  reset: {
    resetPassword: 'Vous avez oublié votre mot de passe ?',
    resetPasswordText: `Saisissez l'adresse électronique associée à votre compte. 
Nous vous enverrons un code de vérification pour réinitialiser votre mot de passe.`,
    temporaryPassword: 'Veuillez consulter votre boîte aux lettres pour obtenir un mot de passe temporaire.',
    confirmResetPasswordText1: 'Un code de vérification a été envoyé à :',
    confirmResetPasswordText2:
      'Veuillez consulter votre boîte aux lettres et suivre les instructions pour réinitialiser votre mot de passe.',
    confirmResetPasswordText3: "Notez que l'e-mail peut se trouver dans votre dossier spam.",
    goToLogin: 'Retourner à la connexion',
  },

  mailSignup: {
    signUp: 'Registre',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    choosePassword: 'Mot de passe',
    hide: 'Cacher',
    show: 'Afficher',
    passwordHelp: 'Exigences minimales : 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre',
    country: 'Pays',
    language: 'Langue',
    receiveInfo: 'Je souhaite recevoir des informations sur Niko',
    textPrivacyPolicy:
      'En soumettant ce formulaire, vous acceptez que Niko traite vos données personnelles conformément à notre',
    privacyPolicy: 'politique de confidentialité',
    termsOfUse1: "Je comprends et j'accepte les",
    termsOfUse2: "conditions d'utilisation",
    mandatoryFields: 'Champs obligatoires',
    dataTreatment: ' sa politique de confidentialité',
  },

  language: {
    french: 'Français',
    english: 'Anglais',
    swedish: 'Suédois',
    danish: 'Danois',
    slovak: 'Slovaque',
    dutch: 'Néerlandais',
    german: 'Allemand',
  },

  country: {
    belgium: 'Belgique',
    denmark: 'Danemark',
    france: 'France',
    sweden: 'Suède',
    slovakia: 'Slovaquie',
    germany: 'Allemagne',
    netherland: 'Pays-Bas',
  },

  error: {
    required: 'Champ obligatoire',
    apicall: "Un problème s'est produit sur le serveur d'api !",
    UserNotFoundException: "L'utilisateur n'existe pas.",
    NotAuthorizedException: "Nom d'utilisateur ou mot de passe incorrect.",
    NotAuthorizedExceptionReset: "Nom d'utilisateur incorrect.",
    UsernameExistsException: "Un compte avec l'adresse électronique donnée existe déjà.",
    InvalidParameterExceptionPassword: `Le mot de passe n'est pas conforme à la politique : Le mot de passe doit :
        avoir une longueur supérieure ou égale à 8, 
        contenir 1 majuscule et 1 minuscule,
        1 chiffre.`,
    InvalidPasswordExceptionLong:
      "Le mot de passe n'est pas conforme à la politique : Le mot de passe n'est pas assez long.",
    InvalidPasswordExceptionLower:
      "Le mot de passe n'est pas conforme à la politique : Le mot de passe doit contenir des caractères minuscules.",
    InvalidPasswordExceptionUpper:
      "Le mot de passe n'est pas conforme à la politique : Le mot de passe doit comporter des caractères majuscules.",
    InvalidPasswordExceptionNumeric:
      "Le mot de passe n'est pas conforme à la politique : Le mot de passe doit comporter des caractères numériques.",
    CodeMismatchException: 'Code de vérification invalide, veuillez réessayer.',
    email: 'Disparu',
    confirmationCode: 'Disparu',
    AuthError: 'Code de confirmation erroné',
    crmError: "Une erreur inattendue s'est produite lors du login, veuillez contacter Niko pour résoudre le problème.",
    invalidPassword: "Votre mot de passe a expiré, un courriel vous a été envoyé avec d'autres instructions.",
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'Les courriels doivent correspondre	',
      newEmailBasic: 'Champ non valide',
      newEmailWrongFormat: 'Format de courrier électronique non valide',
      newEmailUserNotFound: 'Vous devez être connecté pour mettre à jour votre e-mail.',
    },
  },
};
