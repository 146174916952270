import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  buttonRoot: {
    backgroundColor: '#F6F6F6',
    border: 'solid',
    borderColor: '#353535',
    height: '50px',
    marginTop: 22,
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      '& $buttonTypography': {
        color: '#F6F6F6',
      },
    },
  },
  buttonTypography: {
    color: '#353535',
  },
}));

interface CancelButtonProps extends ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
}

const CancelButton: React.FC<CancelButtonProps> = ({ label, onClick, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };
  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.buttonRoot}
        color="primary"
        variant="contained"
        onClick={onClick ?? handleClick}
        fullWidth
        {...rest}
      >
        <Typography className={classes.buttonTypography}>{label}</Typography>
      </Button>
    </div>
  );
};

export default CancelButton;
