import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Container, FormControl, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LoaderButton from 'components/Buttons/LoaderButton';
import { AuthError, useUser } from 'hooks/UserContext';

interface ResetValues {
  email: string;
  defaultErrorMessage?: string;
}

interface ResetEmailFormProps {
  setConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const ResetEmailSchema = Yup.object().shape({
  email: Yup.string().min(1).required(),
});

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
    left: -7.5,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  mainText: {
    marginTop: 10,
    paddingLeft: 15,
  },
}));

const ResetEmailForm: React.FC<ResetEmailFormProps> = ({ setConfirmation, setEmail }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { forgotPassword, setTempUser, createNewContact } = useUser();
  const [showTemporaryPasswordMessage, setShowTemporaryPasswordMessage] = useState<boolean>(false);

  const handleSubmit = async (values: ResetValues, { setSubmitting, setErrors }: FormikHelpers<ResetValues>) => {
    const processedEmail = values.email.includes('@') ? values.email.trim().toLowerCase() : values.email;
    try {
      await forgotPassword(processedEmail);
      setTempUser({ username: processedEmail });
      setConfirmation(true);
      setSubmitting(false);
      setEmail(processedEmail);
    } catch (e) {
      const err = e as AuthError;
      if (
        err.name === 'UserNotFoundException' ||
        (err.name === 'NotAuthorizedException' && err.message === 'User password cannot be reset in the current state.')
      ) {
        try {
          // If we get an error, we check if the user exists in CRM.
          // If it exists, we created it back in Cognito and do the reset password there.
          const response = await createNewContact(processedEmail);
          const {
            result: { crm_user_exists: existsInCrm },
          } = response;
          if (existsInCrm) {
            // Display message to user telling him he will receive an email with a temporary password
            setShowTemporaryPasswordMessage(true);
            return;
          }
          // If it doesn't exists in CRM, then the user simply doesn't exist anywhere.
          // App proceeds to the next step quietly so third parties can't assess if user exists or not.
          setConfirmation(true);
          setEmail(processedEmail);
          setSubmitting(false);
          return;
        } catch (createContactError) {
          const error = createContactError as AuthError;
          setErrors({
            email: error.name,
            defaultErrorMessage: error.message,
          });
          return;
        }
      }
      if (err.name === 'NotAuthorizedException') {
        setErrors({
          email: 'NotAuthorizedExceptionReset',
          defaultErrorMessage: err.message,
        });
        setSubmitting(false);
        return;
      }
      setErrors({ email: err.name, defaultErrorMessage: err.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        {
          email: '',
        } as ResetValues
      }
      validationSchema={ResetEmailSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Form className={classes.form}>
          <Typography variant="h6" className={classes.mainText}>
            {formatMessage({ id: 'reset.resetPasswordText' })}
          </Typography>
          <FormControl
            fullWidth
            style={{
              marginTop: 24,
              borderLeft: errors.email && touched.email ? '3px solid #EA1E27' : '3px solid transparent',
              paddingLeft: errors.email && touched.email ? 15 : 0,
            }}
          >
            <Typography variant="h3" style={{ marginBottom: 12 }}>
              {formatMessage({ id: 'email' })}
            </Typography>
            <TextField
              id="email"
              type="text"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              InputProps={{
                classes: {
                  root: classes.fieldOutlinedInput,
                  focused: classes.fieldFocused,
                  notchedOutline: classes.fieldNotchedOutline,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorText,
                },
              }}
              onBlur={handleBlur}
              error={showTemporaryPasswordMessage || Boolean(errors.email && touched.email)}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                showTemporaryPasswordMessage
                  ? formatMessage({ id: 'reset.temporaryPassword' })
                  : errors.email && touched.email
                  ? formatMessage({
                      id: `error.${errors.email}`,
                      defaultMessage: errors.defaultErrorMessage ?? ' ',
                    })
                  : ''
              }
            />
          </FormControl>
          <Container style={{ width: '100%', margin: 0, padding: '0px 0px 0px 15px' }}>
            <LoaderButton loading={isSubmitting} success={status}>
              <Typography variant="h2">
                {formatMessage({
                  id: 'button.next',
                })}
              </Typography>
            </LoaderButton>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default ResetEmailForm;
