/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { useIntl } from 'react-intl';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { FormControl, TextField, Link, Select, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import LoaderButton from 'components/Buttons/LoaderButton';
import { parseInvalidPasswordException, parseInvalidParameterException } from 'utils/errors';

import { useLocale } from 'i18n/LocaleContext';

import { AuthError, useUser } from 'hooks/UserContext';
import { getLegalPrivacyLocale } from 'i18n/utils';
import useTouch from 'hooks/useTouch';
import CancelButton from 'components/Buttons/CancelButton';
import RequirementsTooltip from 'components/Tooltips/PasswordRequirementsToolTip';
import { useRedirect } from 'hooks/RedirectContext';
import { ReactComponent as PasswordSee } from '../../../img/passwordsee.svg';
import { ReactComponent as PasswordNoSee } from '../../../img/passwordnosee.svg';
import { ReactComponent as DropDown } from '../../../img/dropdown.svg';
import { ReactComponent as CheckBoxOutlineBlankIcon } from '../../../img/checkblank.svg';
import { ReactComponent as CheckBoxIcon } from '../../../img/checked.svg';

const useStyles = makeStyles(() => ({
  form: { position: 'relative', left: -7.5 },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  select: {
    borderWidth: 0,
    borderRadius: 46,
    background: '#F2F2F2',
  },
  selectNotchedOutline: {
    borderWidth: 0,
  },
  selectOutlinedInput: {
    height: 40,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  adornedNoPadding: {
    paddingRight: 0,
  },
  privacyPolicy: {
    textAlign: 'center',

    marginTop: 15,
  },
  iconOutlined: {
    right: '18px',
    top: '45%',
  },
  redStar: {
    color: 'red',
  },
  mandatoryFieldsTextField: {
    marginLeft: 15,
    marginTop: '10px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
}));

interface FormSignUpValues {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  language: string;
  password: string;
  newsletter?: boolean;
  termsOfUse: boolean;
  defaultErrorMessage?: string;
}

YupPassword(Yup);

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('newEmailWrongFormat').min(6).required('required'),
  password: Yup.string().min(8).max(256).minLowercase(1).minUppercase(1).minNumbers(1).required('required'),
  firstName: Yup.string().min(1).required('required'),
  lastName: Yup.string().min(1).required('required'),
  language: Yup.string().required('required'),
  country: Yup.string().required('required'),
  newsletter: Yup.boolean().notRequired(),
  termsOfUse: Yup.boolean().oneOf([true]).required(),
});

const MailSignUpForm: React.FC<RouteChildrenProps> = ({ history }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { signUp } = useUser();
  const { langAndCountryFormated, language: lang, country } = useLocale();
  const { getRedirectUri } = useRedirect();

  const handleSubmit = async (
    values: FormSignUpValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormSignUpValues>,
  ) => {
    try {
      const processedEmail = values.email.trim().toLowerCase();
      await signUp(
        processedEmail,
        values.password,
        values.firstName,
        values.lastName,
        values.country,
        values.language,
        values.newsletter ? values.newsletter : false,
        getRedirectUri() ?? '',
      );
      setSubmitting(false);
      history.push(`/${langAndCountryFormated}/confirm`);
    } catch (e) {
      const err = e as AuthError;
      if (err.name === 'UsernameExistsException') {
        setErrors({ email: err.name, defaultErrorMessage: err.message });
      } else if (err.name === 'InvalidPasswordException') {
        setErrors({ password: parseInvalidPasswordException(err), defaultErrorMessage: err.message });
      } else if (err.name === 'InvalidParameterException') {
        setErrors({ password: parseInvalidParameterException(err), defaultErrorMessage: err.message });
      } else {
        setErrors({ email: err.name, defaultErrorMessage: err.message });
      }

      setStatus(true);
      setSubmitting(false);
    }
  };

  const [isTouched, touchEventListeners] = useTouch();

  return (
    <Formik
      initialValues={
        {
          lastName: '',
          firstName: '',
          email: '',
          password: '',
          language: lang !== '' ? lang : 'nl',
          country: country !== '' ? country : 'BE',
          newsletter: false,
          termsOfUse: false,
          defaultErrorMessage: formatMessage({ id: 'error.required', defaultMessage: 'Required Field' }),
        } as FormSignUpValues
      }
      validationSchema={SignUpSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <>
          <Typography style={{ marginLeft: 15 }} variant="h1">
            {formatMessage({ id: 'signWithEmail' })}
          </Typography>
          <Form className={classes.form}>
            <FormControl
              style={{
                marginTop: 32,
                borderLeft: errors.firstName && touched.firstName ? '3px solid #EA1E27' : '3px solid transparent',
                paddingLeft: 15,
              }}
              fullWidth
            >
              <Typography variant="h3" style={{ marginBottom: 12 }}>
                {formatMessage({ id: 'mailSignup.firstName' })} <span className={classes.redStar}>*</span>
              </Typography>
              <TextField
                id="firstName"
                type="text"
                name="firstName"
                variant="outlined"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  classes: {
                    root: classes.fieldOutlinedInput,
                    focused: classes.fieldFocused,
                    notchedOutline: classes.fieldNotchedOutline,
                  },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorText,
                  },
                }}
                error={Boolean(errors.firstName && touched.firstName)}
                helperText={
                  errors.firstName && touched.firstName
                    ? formatMessage({
                        id: `error.${errors.firstName}`,
                        defaultMessage: errors.defaultErrorMessage,
                      })
                    : ''
                }
                fullWidth
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: 24,
                borderLeft: errors.lastName && touched.lastName ? '3px solid #EA1E27' : '3px solid transparent',
                paddingLeft: 15,
              }}
              fullWidth
            >
              <Typography variant="h3" style={{ marginBottom: 12 }}>
                {formatMessage({ id: 'mailSignup.lastName' })} <span className={classes.redStar}>*</span>
              </Typography>
              <TextField
                id="lastName"
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.fieldOutlinedInput,
                    focused: classes.fieldFocused,
                    notchedOutline: classes.fieldNotchedOutline,
                  },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorText,
                  },
                }}
                error={Boolean(errors.lastName && touched.lastName)}
                helperText={
                  errors.lastName && touched.lastName
                    ? formatMessage({
                        id: `error.${errors.lastName}`,
                        defaultMessage: errors.defaultErrorMessage,
                      })
                    : ''
                }
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: 24,
                borderLeft: errors.email && touched.email ? '3px solid #EA1E27' : '3px solid transparent',
                paddingLeft: 15,
              }}
              fullWidth
            >
              <Typography variant="h3" style={{ marginBottom: 12 }}>
                {formatMessage({ id: 'email' })} <span className={classes.redStar}>*</span>
              </Typography>
              <TextField
                id="email"
                type="email"
                name="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                InputProps={{
                  classes: {
                    root: classes.fieldOutlinedInput,
                    focused: classes.fieldFocused,
                    notchedOutline: classes.fieldNotchedOutline,
                  },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorText,
                  },
                }}
                fullWidth
                error={Boolean(errors.email && touched.email)}
                helperText={
                  errors.email && touched.email
                    ? formatMessage({
                        id: `error.${errors.email}`,
                        defaultMessage: formatMessage({ id: 'error.email' }),
                      })
                    : ''
                }
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: 24,
                borderLeft: errors.password && touched.password ? '3px solid #EA1E27' : '3px solid transparent',
                paddingLeft: 15,
              }}
              fullWidth
            >
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={6} md={8}>
                  <Typography variant="h3" style={{ marginBottom: 12 }}>
                    {formatMessage({ id: 'mailSignup.choosePassword' })} <span className={classes.redStar}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2} style={{ paddingLeft: 15 }}>
                  <RequirementsTooltip />
                </Grid>
              </Grid>
              <TextField
                id="password"
                name="password"
                variant="outlined"
                type={isTouched ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" {...touchEventListeners}>
                      <Button style={{ background: 'transparent', textTransform: 'none' }} disableRipple>
                        {isTouched ? <PasswordNoSee /> : <PasswordSee />}
                      </Button>
                    </InputAdornment>
                  ),

                  classes: {
                    adornedEnd: classes.adornedNoPadding,
                    root: classes.fieldOutlinedInput,
                    focused: classes.fieldFocused,
                    notchedOutline: classes.fieldNotchedOutline,
                  },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorText,
                  },
                }}
                onBlur={handleBlur}
                error={Boolean(errors.password && touched.password)}
                helperText={
                  errors.password && touched.password
                    ? formatMessage({
                        id: `error.${errors.password}`,
                        defaultMessage: errors.defaultErrorMessage
                          ? errors.defaultErrorMessage
                          : formatMessage({ id: 'mailSignup.passwordHelp' }),
                      })
                    : ''
                }
              />
            </FormControl>
            <FormControl style={{ marginTop: 24, paddingLeft: 15 }} fullWidth>
              <Typography variant="h3" style={{ marginBottom: 12 }}>
                {formatMessage({ id: 'mailSignup.country' })}
              </Typography>
              <Select
                native
                IconComponent={DropDown}
                id="countrySelect"
                value={values.country}
                onChange={handleChange}
                variant="outlined"
                inputProps={{
                  name: 'country',
                  id: 'countrySelect',
                  style: { height: '100%' },
                }}
                className={classes.select}
                classes={{
                  iconOutlined: classes.iconOutlined,
                }}
              >
                <option value="BE">{formatMessage({ id: 'country.belgium' })}</option>
                <option value="DK">{formatMessage({ id: 'country.denmark' })}</option>
                <option value="DE">{formatMessage({ id: 'country.germany' })}</option>
                <option value="FR">{formatMessage({ id: 'country.france' })}</option>
                <option value="NL">{formatMessage({ id: 'country.netherland' })}</option>
                <option value="SK">{formatMessage({ id: 'country.slovakia' })}</option>
                <option value="SE">{formatMessage({ id: 'country.sweden' })}</option>
              </Select>
            </FormControl>
            <FormControl style={{ marginTop: 24, paddingLeft: 15 }} fullWidth>
              <Typography variant="h3" style={{ marginBottom: 12 }}>
                {formatMessage({ id: 'mailSignup.language' })}
              </Typography>
              <Select
                native
                IconComponent={DropDown}
                id="languageSelect"
                value={values.language}
                onChange={handleChange}
                variant="outlined"
                inputProps={{
                  name: 'language',
                  id: 'languageSelect',
                  style: { height: '100%' },
                }}
                className={classes.select}
                classes={{
                  iconOutlined: classes.iconOutlined,
                }}
              >
                <option value="da">{formatMessage({ id: 'language.danish' })}</option>
                <option value="de">{formatMessage({ id: 'language.german' })}</option>
                <option value="en">{formatMessage({ id: 'language.english' })}</option>
                <option value="fr">{formatMessage({ id: 'language.french' })}</option>
                <option value="nl">{formatMessage({ id: 'language.dutch' })}</option>
                <option value="sk">{formatMessage({ id: 'language.slovak' })}</option>
                <option value="sv">{formatMessage({ id: 'language.swedish' })}</option>
              </Select>
            </FormControl>
            <FormControlLabel
              style={{
                paddingLeft: 15,
                marginTop: 18,
              }}
              control={
                <Checkbox
                  style={{ color: '#D9D9CF' }}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={values.newsletter}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="newsletter"
                  name="newsletter"
                />
              }
              label={<Typography variant="h3">{formatMessage({ id: 'mailSignup.receiveInfo' })}</Typography>}
            />
            <FormControlLabel
              style={{
                paddingLeft: 15,
                marginTop: 18,
              }}
              control={
                <Checkbox
                  style={{ color: '#D9D9CF' }}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={values.termsOfUse}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="termsOfUse"
                  name="termsOfUse"
                  required
                />
              }
              label={
                <Typography variant="h3">
                  {`${formatMessage({ id: 'mailSignup.termsOfUse1' })} ${formatMessage({
                    id: 'mailSignup.termsOfUse2',
                  })} `}
                  <span className={classes.redStar}>*</span>
                </Typography>
              }
            />
            <div className={classes.mandatoryFieldsTextField}>
              <span className={classes.redStar}>*</span> {formatMessage({ id: 'mailSignup.mandatoryFields' })}
            </div>
            <Container style={{ width: '100%', margin: 0, padding: '0px 0px 0px 15px' }}>
              <CancelButton
                onClick={() => {
                  history.push(`/${langAndCountryFormated}/login`);
                }}
                label={formatMessage({ id: 'button.cancel' })}
              />
              <LoaderButton loading={isSubmitting} success={status} disabled={Object.keys(errors).length !== 0}>
                {formatMessage({ id: 'mailSignup.signUp' })}
              </LoaderButton>
            </Container>
            <Typography variant="h6" className={classes.privacyPolicy}>
              {formatMessage({ id: 'mailSignup.textPrivacyPolicy' })}
              <Link
                underline="always"
                onClick={() => {
                  window.open(
                    `https://niko.eu/${getLegalPrivacyLocale(langAndCountryFormated)}/legal/privacy`,
                    '_blank',
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                {formatMessage({ id: 'mailSignup.privacyPolicy' })}
              </Link>
            </Typography>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default MailSignUpForm;
