import React from 'react';
import { useIntl } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Theme, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoaderButton from 'components/Buttons/LoaderButton';

import { useLocale } from 'i18n/LocaleContext';
import { appBarHeight } from 'App';
import { AuthError, useUser } from 'hooks/UserContext';
import { useRedirect } from 'hooks/RedirectContext';
import CustomAppBar from 'components/Tabs/CustomAppBar';

interface ChangeEmailValues {
  newEmail: string;
  confirmNewEmail: string;
  defaultErrorMessage?: string;
}
const ChangeEmailSchema = Yup.object().shape({
  newEmail: Yup.string().email('newEmailWrongFormat').required('required'),
  confirmNewEmail: Yup.string()
    .oneOf([Yup.ref('newEmail'), null], 'newEmailMismatch')
    .required('required'),
});

const useStyles = makeStyles((theme: Theme) => ({
  emailChangeTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },
  backBar: {
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(242, 242, 242, 1)',
    },
    height: 54,
    background: 'rgba(77, 77, 79, 1)',
  },
  backToolBar: {
    height: 54,
    verticalAlign: 'center',
    padding: 0,
  },
  backArrow: {
    [theme.breakpoints.down('xs')]: {
      color: 'rgba(41, 41, 41, 1)',
    },
    color: 'rgba(255, 255, 255, 1)',
    marginLeft: 24,
    marginRight: 16,
    width: 21,
  },
  backText: {
    [theme.breakpoints.down('xs')]: {
      color: 'black',
    },
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 1)',
  },
  changeEmailBody: {
    paddingTop: 40,
    padding: 48,
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
}));

const ChangeEmail: React.FC<RouteChildrenProps> = ({ history }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { langAndCountryFormated } = useLocale();
  const { user, updateEmail } = useUser();
  const { getRefererUri, getRedirectUri } = useRedirect();

  const handleSubmit = async (
    values: ChangeEmailValues,
    { setSubmitting, setErrors }: FormikHelpers<ChangeEmailValues>,
  ) => {
    try {
      if (!user) {
        throw new ReferenceError('newEmailUserNotFound');
      }
      await updateEmail(user, values.newEmail, getRedirectUri() ?? '');
      history.push(`/${langAndCountryFormated}/changeEmail/confirm`);
    } catch (e) {
      const err = e as AuthError;
      if (err.message === 'newEmailUserNotFound')
        setErrors({
          confirmNewEmail: err.name,
          defaultErrorMessage: formatMessage({ id: `error.newEmail.${err.message}` }),
        });
      else {
        setErrors({ confirmNewEmail: err.name, defaultErrorMessage: err.message });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    const refererUri = getRefererUri();
    const redirectUri = getRedirectUri();
    // eslint-disable-next-line no-console
    console.log(getRefererUri());
    if (refererUri) {
      window.location.replace(refererUri);
      return;
    }
    if (redirectUri) {
      window.location.replace(redirectUri);
      return;
    }
    history.push(`/${langAndCountryFormated}/exit`);
  };

  return (
    <Formik
      initialValues={
        {
          newEmail: '',
          confirmNewEmail: '',
          defaultErrorMessage: '',
        } as ChangeEmailValues
      }
      validationSchema={ChangeEmailSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Container component="main" maxWidth={false} className={classes.emailChangeTab}>
          <CustomAppBar handleCancelClick={handleCancelClick} />
          <Container className={classes.changeEmailBody}>
            <Form>
              <FormControl fullWidth>
                <Typography variant="h3">{formatMessage({ id: 'newEmailTextField' })}</Typography>
                <TextField
                  id="newEmail"
                  type="text"
                  name="newEmail"
                  autoComplete="new email"
                  margin="normal"
                  variant="outlined"
                  value={values.newEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    classes: {
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  autoFocus
                  fullWidth
                  error={Boolean(errors.newEmail && touched.newEmail)}
                  helperText={
                    errors.newEmail && touched.newEmail
                      ? formatMessage({
                          id: `error.newEmail.${errors.newEmail}`,
                          defaultMessage: errors.defaultErrorMessage
                            ? errors.defaultErrorMessage
                            : formatMessage({ id: 'error.newEmail.newEmailBasic' }),
                        })
                      : ' '
                  }
                />
              </FormControl>
              <FormControl style={{ marginTop: 24 }} fullWidth>
                <Typography variant="h3">{formatMessage({ id: 'confirmNewEmailTextField' })}</Typography>
                <TextField
                  id="confirmNewEmail"
                  type="text"
                  name="confirmNewEmail"
                  autoComplete="confirm new email"
                  margin="normal"
                  variant="outlined"
                  value={values.confirmNewEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    classes: {
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  fullWidth
                  error={Boolean(errors.confirmNewEmail && touched.confirmNewEmail)}
                  helperText={
                    errors.confirmNewEmail && touched.confirmNewEmail
                      ? formatMessage({
                          id: `error.newEmail.${errors.confirmNewEmail}`,
                          defaultMessage: errors.defaultErrorMessage
                            ? errors.defaultErrorMessage
                            : formatMessage({ id: 'error.newEmail.newEmailBasic' }),
                        })
                      : ' '
                  }
                />
              </FormControl>
              <LoaderButton
                loading={isSubmitting}
                disabled={Object.keys(errors).length !== 0 || isSubmitting}
                success={status}
              >
                {formatMessage({ id: 'send' })}
              </LoaderButton>
            </Form>
          </Container>
        </Container>
      )}
    </Formik>
  );
};

export default ChangeEmail;
