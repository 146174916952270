import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#353535',
  },
  tooltip: {
    background: '#353535',
    opacity: 0.8,
    borderRadius: '4px',
    color: '#FFFFFF',
  },
  icon: {
    color: '#353535',
    strokeWidth: 1,
    stroke: '#FFFFFF',
  },
}));
const RequirementsTooltip: React.FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <div>
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        placement="top-end"
        arrow
        title={formatMessage({ id: 'newpassword.minRequirements' })}
      >
        <InfoOutlinedIcon className={classes.icon} />
      </Tooltip>
    </div>
  );
};

export default RequirementsTooltip;
