const legalPricacyLocales = ['nl-BE', 'fr-BE', 'da-DK', 'de-DE', 'fr-FR', 'en', 'nl-NL', 'sk-SK', 'sv-SE'];

export const flattenMessages = (nestedMessages: Record<string, string>, prefix = ''): Record<string, string> =>
  Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value; // eslint-disable-line
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {} as Record<string, string>);

export const getLanguageCode = (locale: string): string => {
  const DEFAULT_LANG = 'en';
  if (typeof locale === 'undefined' || locale === null) {
    return DEFAULT_LANG;
  }
  const match = locale.match(/^([a-z]{2})-([A-Z]{2})$/);
  if (!match || match.length < 1) return DEFAULT_LANG;
  return match[1];
};

export const getCountryCode = (locale: string): string => {
  const DEFAULT_COUNTRY = 'BE';
  if (typeof locale === 'undefined' || locale === null) {
    return DEFAULT_COUNTRY;
  }
  const match = locale.match(/^([a-z]{2})-([A-Z]{2})$/);
  if (!match || match.length < 2) return DEFAULT_COUNTRY;
  return match[2];
};

export const getLegalPrivacyLocale = (locale: string): string => {
  if (legalPricacyLocales.includes(locale)) {
    return locale;
  }
  if (locale === 'nl') {
    return 'nl-NL';
  }
  if (locale === 'fr') {
    return 'fr-FR';
  }
  if (locale === 'da') {
    return 'da-DK';
  }
  if (locale === 'sk') {
    return 'sk-SK';
  }
  if (locale === 'sv') {
    return 'sv-SE';
  }
  return getLanguageCode(locale);
};
