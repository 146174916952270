/* eslint-disable no-shadow */
import React, { useState, useEffect, useContext } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { API } from '@aws-amplify/api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ISignUpResult, CognitoUser } from 'amazon-cognito-identity-js';
import { getCountryCode, getLanguageCode } from '../i18n/utils';

export interface AuthError extends Error {
  code?: string;
}

/**
 * Types
 */
export interface IUserContext {
  user: CognitoUser | null;
  tempUser: any;
  setUser: (user: CognitoUser | null) => void;
  setTempUser: (tempUser: object | null) => void;
  changePasswordChallenge: boolean;
  setChangePasswordChallenge: (changePasswordChallenge: boolean) => void;
  changeEmailStatus: { isConfirmed: boolean; timestamp: Date } | null;
  setChangeEmailStatus: (changeEmailStatus: { isConfirmed: boolean; timestamp: Date }) => void;
  login: (email: string, password: string) => Promise<any>;
  completeNewPassword: (user: CognitoUser, newPassword: string) => Promise<any>;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    country: string,
    language: string,
    newsletter: boolean,
    redirectUrl: string,
    attributes?: Record<string, string>[],
    preferedUsername?: string,
  ) => Promise<any>;
  confirmSignUp: (email: string, confirmationCode: string) => Promise<any>;
  resendSignUp: (username: string, redirectUrl: string) => Promise<any>;
  forgotPassword: (email: string) => Promise<any>;
  forgotPasswordSubmit: (email: string, confirmationCode: string, newPassword: string) => Promise<any>;
  logout: () => Promise<any>;
  updatePreferredUsernameAttribute: (user: CognitoUser, preferredUsername: string) => Promise<any>;
  checkCrmUserExists: (
    email: string,
    firstName: string,
    lastName: string,
    locale: string,
    userSub: string,
  ) => Promise<any>;
  checkPasswordHashUrl: (username: string, password: string) => Promise<any>;
  createNewContact: (username: string) => Promise<any>;
  updatePassword: (user: CognitoUser, oldPassword: string, newPassword: string) => Promise<any>;
  updateEmail: (user: CognitoUser, newEmail: string, redirectUrl: string) => Promise<any>;
  confirmUpdateEmail: (user: CognitoUser, confirmationCode: string) => Promise<any>;
  cancelUpdateEmail: (user: CognitoUser) => Promise<any>;
  resendUserAttributeConfirmationCode: (userAttribute: string) => void;
}

const API_NAME = 'nikoUserCheck';

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
export const UserContext = React.createContext<IUserContext | null>(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [tempUser, setTempUser] = useState<object | null>(null);
  const [changePasswordChallenge, setChangePasswordChallenge] = useState<boolean>(false);
  const [changeEmailStatus, setChangeEmailStatus] = useState<{ isConfirmed: boolean; timestamp: Date } | null>(null);

  const changeEmailStatusHandler = async (user: CognitoUser): Promise<any> => {
    user.getUserAttributes(async (err, attributes) => {
      const ces = attributes?.find(attr => attr.Name === 'custom:emailChangeStatus');

      if (!ces) {
        setChangeEmailStatus(null);
        return;
      }
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (new Date(JSON.parse(ces.Value).timestamp) < yesterday) {
        await Auth.deleteUserAttributes(user, ['custom:emailChangeStatus']);
        setChangeEmailStatus(null);
      } else {
        setChangeEmailStatus(JSON.parse(ces.Value));
      }
    });
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const authUser: CognitoUser = await Auth.currentAuthenticatedUser();
        // console.log('currentUser', user);
        authUser.getUserAttributes((err, attributes) => {
          if (attributes?.find(attribute => attribute.Name === 'email_verified')?.Value === 'true') {
            setUser(authUser);
          }
        });
      } catch (err) {
        setUser(null);
      }
    };
    getUser();

    // set listener for auth events
    Hub.listen('auth', data => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        getUser();
      }
      // this listener is needed for form sign ups since the OAuth will redirect & reload
      if (payload.event === 'signOut') {
        setTimeout(() => {
          setUser(null);
        }, 350);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      const fetchEmailStatus = async () => changeEmailStatusHandler(user);
      fetchEmailStatus();
    } else {
      setChangeEmailStatus(null);
    }
  }, [user]);

  const login = async (email: string, password: string): Promise<any> => {
    const cognitoUser = await Auth.signIn(email, password);
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setChangePasswordChallenge(true);
    }

    return cognitoUser;
  };

  const logout = async (): Promise<any> => {
    const data = await Auth.signOut();
    setUser(null);
    setTempUser(null);
    return data;
  };

  const completeNewPassword = async (user: CognitoUser, newPassword: string): Promise<any> => {
    const resultUser = await Auth.completeNewPassword(user, newPassword, null);
    setChangePasswordChallenge(false);
    setUser(resultUser);
    return resultUser;
  };
  /**
   * Check if the user already exist in backend.
   * @param email
   * @param firstName
   * @param lastName
   * @param language
   */
  const checkCrmUserExists = (
    email: string,
    firstName: string,
    lastName: string,
    locale: string,
    userSub: string,
  ): Promise<any> => {
    const language = getLanguageCode(locale);
    const country = getCountryCode(locale);
    const path = '/checkCrmUserExists';
    const init = {
      body: {
        username: email,
        firstname: firstName,
        lastname: lastName,
        language,
        country,
        userSub,
      },
      headers: {},
    };

    return API.post(API_NAME, path, init);
  };

  /**
   * Check if the user already exist in backend.
   * @param username
   * @param password
   */
  const checkPasswordHashUrl = (username: string, password: string): Promise<any> => {
    const path = '/checkPasswordHashUrl';
    const init = {
      body: {
        username,
        password,
      },
      headers: {},
    };

    return API.post(API_NAME, path, init);
  };

  /**
   * Check if the user already exist in backend.
   * @param username
   * @param password
   */
  const createNewContact = (username: string): Promise<any> => {
    const path = '/createNewContact';
    const init = {
      body: {
        username,
      },
      headers: {},
    };

    return API.post(API_NAME, path, init);
  };

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    country: string,
    language: string,
    newsletter: boolean,
    redirectUrl: string,
    // eslint-disable-next-line default-param-last
    attributes: Record<string, string>[] = [],
  ): Promise<ISignUpResult> => {
    const user = {
      username: email,
      password,
      attributes: {
        email,
        name: firstName,
        family_name: lastName,
        locale: `${language}-${country}`,
        'custom:newsletter': `${newsletter}`,
        ...attributes,
      },
      validationData: [],
      clientMetadata: { redirectUrl: `https://${window.location.hostname}/confirm?redirect_uri=${redirectUrl}` },
    };

    return Auth.signUp(user)
      .then(cognitoUser => {
        setTempUser({ cognitoUser, ...user });
        return cognitoUser;
      })
      .catch(err => {
        throw err;
      });
  };

  const confirmSignUp = (email: string, confirmationCode: string) =>
    Auth.confirmSignUp(email, confirmationCode, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
    })
      .then(data => data)
      .catch(err => {
        throw err;
      });

  const resendSignUp = (username: string, redirectUrl: string) =>
    Auth.resendSignUp(username, {
      redirectUrl: `https://${window.location.hostname}/confirm?redirect_uri=${redirectUrl}`,
    })
      .then(data => data)
      .catch(err => {
        throw err;
      });

  const updatePreferredUsernameAttribute = (user: CognitoUser, preferredUsername: string): Promise<any> =>
    Auth.updateUserAttributes(user, {
      preferred_username: preferredUsername,
    })
      .then(data => data)
      .catch(err => {
        throw err;
      });

  const forgotPassword = (email: string) =>
    Auth.forgotPassword(email)
      .then(data => data)
      .catch(err => {
        throw err;
      });

  const forgotPasswordSubmit = (email: string, confirmationCode: string, newPassword: string) =>
    Auth.forgotPasswordSubmit(email, confirmationCode.trim(), newPassword)
      .then(data => data)
      .catch(err => {
        throw err;
      });

  const updatePassword = async (user: CognitoUser, oldPassword: string, newPassword: string): Promise<any> => {
    await Auth.changePassword(user, oldPassword, newPassword);
  };

  const updateEmail = async (user: CognitoUser, newEmail: string, redirectUrl: string): Promise<any> => {
    await Auth.updateUserAttributes(
      user,
      {
        email: newEmail,
        'custom:emailChangeStatus': JSON.stringify({ isConfirmed: false, timestamp: new Date() }),
      },
      { redirectUrl: `https://${window.location.hostname}/changeEmail/confirm?redirect_uri=${redirectUrl}` },
    );
    setChangeEmailStatus({ isConfirmed: false, timestamp: new Date() });
  };

  // There's no 'AdminVerifyUserAttribute' so the confirmation code must be used in front end.
  // If the code is OK, then we call the back end to sync with CRM.
  const confirmUpdateEmail = async (user: CognitoUser, confirmationCode: string): Promise<any> => {
    await Auth.verifyUserAttributeSubmit(user, 'email', confirmationCode);
    await Auth.deleteUserAttributes(user, ['custom:emailChangeStatus']);

    const path = '/updateContactEmail';
    const init = {
      body: {
        userSub: await user.getUsername(),
        userAttributes: await Auth.userAttributes(user),
      },
      headers: {},
    };
    // I don't like this, but it seems correct.
    return API.post(API_NAME, path, init);
  };

  const cancelUpdateEmail = async (user: CognitoUser): Promise<any> => {
    await Auth.deleteUserAttributes(user, ['custom:emailChangeStatus']);
    setChangeEmailStatus(null);
  };

  const resendUserAttributeConfirmationCode = (userAttribute: string) => {
    if (!user) throw new Error('newEmailUserNotFound');
    return user?.getAttributeVerificationCode(userAttribute, {
      onSuccess: success => success,
      onFailure: err => {
        throw err;
      },
    });
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  const values = React.useMemo(
    () => ({
      user,
      tempUser,
      setUser,
      setTempUser,
      login,
      logout,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      updatePreferredUsernameAttribute,
      checkCrmUserExists,
      checkPasswordHashUrl,
      createNewContact,
      changePasswordChallenge,
      setChangePasswordChallenge,
      changeEmailStatus,
      setChangeEmailStatus,
      completeNewPassword,
      updatePassword,
      updateEmail,
      confirmUpdateEmail,
      cancelUpdateEmail,
      resendUserAttributeConfirmationCode,
    }),
    [user, tempUser, changePasswordChallenge, changeEmailStatus],
  );

  // Finally, return the interface that we want to expose to our other components
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = (): IUserContext => {
  const context = useContext<IUserContext | null>(UserContext);

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context as IUserContext;
};
