export default {
  send: 'Forsendelse',
  email: 'E-mailadres',
  back: 'Tilbage',
  signWithEmail: 'Registrer dig med e-mail',
  tabLogin: 'Log ind',
  tabSign: 'Registrer',
  newPassword: 'Ny adgangskode',
  newEmailTextField: 'Ny e-mail',
  confirmNewEmailTextField: 'Bekræft ny e-mail',

  confirm: {
    confirm: 'Bekræft',
    finish: 'Færdig',
    code: 'Bekræftelseskode',
    title: 'Udfyld venligst bekræftelseskoden',
    text1: 'Vi har netop sendt dig en e-mail.',
    text2: 'Tjek venligst din postkasse og følg instruktionerne for at bekræfte din konto.',
    text3: 'Bemærk, at e-mailen kan ligge i din spam-mappe.',
    required: 'Verifikationskode er et obligatorisk felt',
    resendCode: 'Send kode igen',
    resendCodePrompt: 'Der er blevet sendt en ny bekræftelseskode, se venligst din indbakke.',
  },

  newpassword: {
    title: 'Indtast venligst din nye adgangskode',
    minRequirements: 'Mindstekrav: 8 tegn, 1 stort bogstav, 1 lille bogstav, 1 tal',
  },

  button: {
    cancel: 'Annuller',
    cancelRequest: 'Annuller anmodning',
    continue: 'Fortsæt',
    next: 'Næste',
    finish: 'Afslutte',
  },

  login: {
    password: 'Adgangskode',
    connect: 'Log ind',
    connectUsing: 'Log ind med',
    withEmail: 'eller med e-mail',
    passwordHelp: 'Mindstekrav: 8 tegn, 1 stort bogstav, 1 lille bogstav, 1 tal',
    logout: 'Log ud',
    remember: 'Husk mine oplysninger',
    forgot: 'Glemt adgangskode',
  },

  signup: {
    with: 'Registrer dig hos ',
    or: 'eller',
  },

  reset: {
    resetPassword: 'Har du glemt din adgangskode?	',
    resetPasswordText: `Indtast den e-mailadresse, der er knyttet til din konto. 
Vi sender dig en bekræftelseskode til at nulstille din adgangskode.`,
    temporaryPassword: 'Tjek venligst din postkasse for at få en midlertidig adgangskode.',
    confirmResetPasswordText1: 'Der er blevet sendt en bekræftelseskode til:',
    confirmResetPasswordText2: `Tjek venligst din postkasse og følg 
    instruktionerne for at nulstille din adgangskode.`,
    confirmResetPasswordText3: 'Bemærk, at e-mailen kan være i din spam-mappe',
    goToLogin: 'Gå tilbage til login',
  },

  mailSignup: {
    signUp: 'Registrer',
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    choosePassword: 'Adgangskode',
    hide: 'Skjul',
    show: 'Vis',
    passwordHelp: 'Mindstekrav: 8 tegn, 1 stort bogstav, 1 lille bogstav, 1 tal',
    country: 'Land',
    language: 'Sprog',
    receiveInfo: 'Jeg ønsker at modtage oplysninger om Niko',
    textPrivacyPolicy: `Ved at indsende denne formular accepterer du, 
      at Niko må behandle dine personlige oplysninger i overensstemmelse med vores`,
    privacyPolicy: 'privatlivspolitik',
    termsOfUse1: 'Jeg forstår og accepterer	',
    termsOfUse2: 'vilkår for brug',
    mandatoryFields: 'Obligatoriske felter',
    dataTreatment: ' dens politik om beskyttelse af personlige oplysninger',
  },

  language: {
    french: 'Fransk',
    english: 'Engelsk',
    swedish: 'Svensk',
    danish: 'Dansk',
    slovak: 'Slovakisk',
    dutch: 'Nederlandsk',
    german: 'Tysk',
  },

  country: {
    belgium: 'Belgien',
    denmark: 'Danmark',
    france: 'Frankrig',
    sweden: 'Sverige',
    slovakia: 'Slovakiet',
    germany: 'Tyskland',
    netherland: 'Nederland',
  },

  error: {
    required: 'Obligatorisk felt',
    apicall: 'Noget gik galt på api-serveren!',
    UserNotFoundException: 'Brugeren findes ikke.',
    NotAuthorizedException: 'Forkert brugernavn eller adgangskode.',
    NotAuthorizedExceptionReset: 'Forkert brugernavn.',
    UsernameExistsException: 'Der findes allerede en konto med den angivne e-mail.',
    InvalidParameterExceptionPassword: `Adgangskoden var ikke i overensstemmelse med politikken: Adgangskode skal :
        have en længde på 8 eller derover, 
        indeholde 1 store og små bogstaver,
        1 tal.`,
    InvalidPasswordExceptionLong: 'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskode ikke lang nok.',
    InvalidPasswordExceptionLower:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskode skal indeholde små bogstaver.',
    InvalidPasswordExceptionUpper:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskode skal indeholde store bogstaver.',
    InvalidPasswordExceptionNumeric:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskode skal indeholde numeriske tegn.',
    CodeMismatchException: 'Ugyldig bekræftelseskode angivet, prøv venligst igen.',
    email: 'Manglende',
    confirmationCode: 'Manglende',
    AuthError: 'Forkert bekræftelseskode',
    crmError: 'Der opstod en uventet fejl under login, kontakt venligst Niko for at løse problemet.',
    invalidPassword: 'Din adgangskode er udløbet, og der er sendt en e-mail med yderligere instruktioner til dig.',
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'E-mails skal stemme overens',
      newEmailBasic: 'Ugyldigt felt',
      newEmailWrongFormat: 'Ikke et gyldigt e-mail-format',
      newEmailUserNotFound: 'Du skal være logget ind for at opdatere din e-mail.',
    },
  },
};
