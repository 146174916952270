export default {
  send: 'Verzenden',
  email: 'E-mail',
  back: 'Terug',
  signWithEmail: 'Registreer met je e-mailadres',
  tabLogin: 'Inloggen',
  tabSign: 'Registreren',
  newPassword: 'Nieuw wachtwoord',
  newEmailTextField: 'Nieuw e-mailadres',
  confirmNewEmailTextField: 'Bevestig je nieuw e-mailadres',

  confirm: {
    confirm: 'Bevestigen',
    finish: 'Beëindigen',
    code: 'Verificatiecode',
    title: 'Vul de verificatiecode in',
    text1: 'We hebben je net een e-mail gestuurd.',
    text2: 'Controleer je mailbox en volg de instructies om je account te verifiëren.',
    text3: 'Merk op dat de e-mail misschien in je spam-map zit.',
    required: 'Verificatiecode is een verplicht veld',
    resendCode: 'Code opnieuw verzenden',
    resendCodePrompt: 'Er is een nieuwe verificatiecode verzonden, controleer je inbox.',
  },

  newpassword: {
    title: 'Voer je nieuwe wachtwoord in',
    minRequirements: 'Minimumvereisten: 8 tekens, 1 hoofdletter, 1 kleine letter, 1 cijfer',
  },

  button: {
    cancel: 'Annuleren',
    cancelRequest: 'Aanvraag annuleren	',
    continue: 'Doorgaan',
    next: 'Volgende',
    finish: 'Beëindigen',
  },

  login: {
    password: 'Wachtwoord',
    connect: 'Inloggen',
    connectUsing: 'Inloggen met',
    withEmail: 'of met e-mailadres',
    passwordHelp: 'Minimumvereisten: 8 tekens, 1 hoofdletter, 1 kleine letter, 1 cijfer',
    logout: 'Uitloggen',
    remember: 'Onthoud mijn informatie',
    forgot: 'Wachtwoord vergeten',
  },

  signup: {
    with: 'Registreer met ',
    or: 'of',
  },

  reset: {
    resetPassword: 'Wachtwoord vergeten?',
    resetPasswordText: `Voer het e-mailadres in dat bij uw account hoort. 
Wij sturen u een verificatiecode om uw wachtwoord opnieuw in te stellen.`,
    temporaryPassword: 'Controleer uw mailbox voor een tijdelijk wachtwoord',
    confirmResetPasswordText1: 'Er is een verificatiecode gestuurd naar:',
    confirmResetPasswordText2: `Controleer uw mailbox en volg de instructies
     om uw wachtwoord opnieuw in te stellen.`,
    confirmResetPasswordText3: 'Merk op dat de e-mail misschien in je spam-map zit.',
    goToLogin: 'Ga terug naar inloggen',
  },

  mailSignup: {
    signUp: 'Registreer',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    choosePassword: 'Wachtwoord',
    hide: 'Verberg',
    show: 'Toon',
    passwordHelp: 'Minimumvereisten: 8 tekens, 1 hoofdletter, 1 kleine letter, 1 cijfer',
    country: 'Land',
    language: 'Taal',
    receiveInfo: 'Ik wil Niko informatie ontvangen',
    textPrivacyPolicy:
      'Door dit formulier in te dienen ga je ermee akkoord dat Niko jouw persoonlijke gegevens verwerkt volgens ons ',
    privacyPolicy: 'privacybeleid',
    termsOfUse1: 'Ik begrijp en accepteer de',
    termsOfUse2: 'gebruiksvoorwaarden',
    mandatoryFields: 'Verplichte velden	',
    dataTreatment: ' haar privacybeleid',
  },

  language: {
    french: 'Frans',
    english: 'Engels',
    swedish: 'Zweeds',
    danish: 'Deens',
    slovak: 'Slovaaks',
    dutch: 'Nederlands',
    german: 'Duits',
  },

  country: {
    belgium: 'België',
    denmark: 'Denemarken',
    france: 'Frankrijk',
    sweden: 'Zweden',
    slovakia: 'Slovakije',
    germany: 'Duitsland',
    netherland: 'Nederland',
  },

  error: {
    required: 'Verplicht veld',
    apicall: 'Er ging iets mis op de api server!',
    UserNotFoundException: 'Gebruiker bestaat niet.',
    NotAuthorizedException: 'Onjuiste gebruikersnaam of wachtwoord.',
    NotAuthorizedExceptionReset: 'Onjuiste gebruikersnaam.',
    UsernameExistsException: 'Er bestaat al een account met het opgegeven e-mailadres.',
    InvalidParameterExceptionPassword: `Wachtwoord kwam niet overeen met het beleid: 
        Wachtwoord moet een lengte hebben van 8 of meer, 
        1 hoofdletter en 1 kleine letter bevatten,
        1 cijfer.`,
    InvalidPasswordExceptionLong: 'Wachtwoord was niet in overeenstemming met het beleid: Wachtwoord niet lang genoeg.',
    InvalidPasswordExceptionLower: 'Wachtwoord voldeed niet aan het beleid: Wachtwoord moet kleine letters bevatten.',
    InvalidPasswordExceptionUpper: 'Wachtwoord voldeed niet aan het beleid: Wachtwoord moet hoofdletters bevatten.',
    InvalidPasswordExceptionNumeric:
      'Wachtwoord voldeed niet aan het beleid: Wachtwoord moet numerieke tekens bevatten.',
    CodeMismatchException: 'Ongeldige verificatiecode, probeer het opnieuw.',
    email: 'Ontbrekende',
    confirmationCode: 'Ontbrekende',
    AuthError: 'Verkeerde bevestigingscode',
    crmError: `Er is een onverwachte fout opgetreden tijdens het inloggen,
     neem contact op met Niko om het probleem op te lossen.`,
    invalidPassword: 'Uw wachtwoord is verlopen, er is u een e-mail gestuurd met verdere instructies.',
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'E-mails moeten overeenkomen',
      newEmailBasic: 'Ongeldig veld',
      newEmailWrongFormat: 'Geen geldig e-mailadres formaat',
      newEmailUserNotFound: 'U moet ingelogd zijn om uw e-mailadres bij te werken.',
    },
  },
};
