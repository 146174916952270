import React from 'react';

import { Button, FormControl, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useFormikContext } from 'formik';
import useTouch from 'hooks/useTouch';
import { ReactComponent as PasswordNoSee } from '../../img/passwordnosee.svg';
import { ReactComponent as PasswordSee } from '../../img/passwordsee.svg';

const useStyles = makeStyles(() => ({
  changePasswordBody: {
    paddingTop: 40,
    padding: 48,
  },
  adornedNoPadding: {
    paddingRight: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  labels: {
    marginBottom: 12,
    marginTop: 12,
  },
}));

const PasswordField: React.FC<{ name: string; fieldLabel: string; helperText: String }> = ({
  name,
  fieldLabel,
  helperText,
}) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<{
    [key: string]: string;
    defaultErrorMessage: string;
  }>();
  const classes = useStyles();
  const [isTouched, touchEventListeners] = useTouch();

  return (
    <FormControl
      fullWidth
      style={{
        marginTop: 24,
        borderLeft: errors[name] && touched[name] ? '3px solid #EA1E27' : '3px solid transparent',
        paddingLeft: errors[name] && touched[name] ? 15 : 0,
      }}
    >
      <Typography variant="h3" className={classes.labels}>
        {fieldLabel}
      </Typography>
      <TextField
        id={name}
        variant="outlined"
        type={isTouched ? 'text' : 'password'}
        value={values[name]}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" {...touchEventListeners}>
              <Button style={{ background: 'transparent', textTransform: 'none' }} disableRipple>
                {isTouched ? <PasswordNoSee /> : <PasswordSee />}
              </Button>
            </InputAdornment>
          ),
          classes: {
            adornedEnd: classes.adornedNoPadding,
            root: classes.fieldOutlinedInput,
            focused: classes.fieldFocused,
            notchedOutline: classes.fieldNotchedOutline,
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.errorText,
          },
        }}
        onBlur={handleBlur}
        error={Boolean(errors[name] && touched[name])}
        helperText={helperText}
      />
    </FormControl>
  );
};

export default PasswordField;
