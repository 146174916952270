import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useUser } from 'hooks/UserContext';
import { useLocale } from 'i18n/LocaleContext';
import { useRedirect } from 'hooks/RedirectContext';

const useStyles = makeStyles(() => ({
  tabWrapper: {
    padding: 20,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ExitCard: React.FC<{ title: string }> = props => {
  const { formatMessage } = useIntl();
  const { logout } = useUser();
  const { langAndCountryFormated } = useLocale();
  const history = useHistory();
  const classes = useStyles();
  const { nikoUrl } = useRedirect();
  const { title } = props;

  return (
    <Container className={classes.tabWrapper}>
      <Typography variant="h1">{title}</Typography>
      <br />
      <Typography variant="h3" style={{ textAlign: 'center' }}>
        {formatMessage({ id: 'exit.exitMessage1' })} <a href={nikoUrl}>{formatMessage({ id: 'exit.exitMessage2' })}</a>
      </Typography>

      <Button
        style={{ marginTop: 30 }}
        onClick={async () => {
          await logout();
          history.push(`/${langAndCountryFormated}/login`);
          return null;
        }}
      >
        Log out
      </Button>
    </Container>
  );
};

export default ExitCard;
