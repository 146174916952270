import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Container, Theme, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { appBarHeight } from 'App';
import { useLocale } from 'i18n/LocaleContext';
import { AuthError, useUser } from 'hooks/UserContext';
import { useRedirect } from 'hooks/RedirectContext';
import LoaderButton from 'components/Buttons/LoaderButton';
import CustomAppBar from 'components/Tabs/CustomAppBar';
import PasswordField from 'components/PasswordField/PasswordField';

export interface ChangePasswordValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  defaultErrorMessage?: string;
}

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('required'),
  newPassword: Yup.string().min(8).max(256).minLowercase(1).minUppercase(1).minNumbers(1).required('required'),
  confirmNewPassword: Yup.string()
    .min(8)
    .max(256)
    .minLowercase(1)
    .minUppercase(1)
    .minNumbers(1)
    .oneOf([Yup.ref('newPassword'), null], 'newPasswordMismatch')
    .required('required'),
});

const useStyles = makeStyles((theme: Theme) => ({
  changePasswordTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },
  changePasswordBody: {
    paddingTop: 40,
    padding: 48,
  },
  adornedNoPadding: {
    paddingRight: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  form: {
    position: 'relative',
    left: -7.5,
  },

  mainText: {
    marginTop: 10,
    paddingLeft: 15,
  },
  labels: {
    marginBottom: 12,
    marginTop: 12,
  },
}));

const ChangePassword: React.FC<RouteChildrenProps> = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { getRefererUri, getRedirectUri } = useRedirect();
  const { user, updatePassword } = useUser();
  const classes = useStyles();
  const { langAndCountryFormated } = useLocale();

  const handleSubmit = async (
    values: ChangePasswordValues,
    { setSubmitting, setErrors }: FormikHelpers<ChangePasswordValues>,
  ) => {
    const { oldPassword, newPassword } = values;
    try {
      if (!user) {
        throw new ReferenceError('newPasswordUserNotFound');
      }
      await updatePassword(user, oldPassword, newPassword);
      history.push(`/${langAndCountryFormated}/logout`);
    } catch (e) {
      const err = e as AuthError;
      if (err.message === 'newPasswordUserNotFound')
        setErrors({ oldPassword: err.name, defaultErrorMessage: formatMessage({ id: `error.${err.message}` }) });
      else {
        setErrors({ confirmNewPassword: err.name, defaultErrorMessage: err.message });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    const refererUri = getRefererUri();
    const redirectUri = getRedirectUri();
    // eslint-disable-next-line no-console
    console.log(getRefererUri());
    if (refererUri) {
      window.location.replace(refererUri);
      return;
    }
    if (redirectUri) {
      window.location.replace(redirectUri);
      return;
    }
    history.push(`/${langAndCountryFormated}/exit`);
  };

  return (
    <Formik
      initialValues={
        {
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
          defaultErrorMessage: formatMessage({ id: 'error.required', defaultMessage: 'Required Field' }),
        } as ChangePasswordValues
      }
      validationSchema={ChangePasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, status }) => (
        <Container component="main" maxWidth={false} className={classes.changePasswordTab}>
          <CustomAppBar handleCancelClick={handleCancelClick} />
          <Container className={classes.changePasswordBody}>
            <Form className={classes.form}>
              <Typography variant="h6" className={classes.mainText}>
                {formatMessage({ id: 'changePassword.text' })}
              </Typography>
              <PasswordField
                name="oldPassword"
                fieldLabel={formatMessage({ id: 'changePassword.oldPassword' })}
                helperText={
                  errors.oldPassword && touched.oldPassword
                    ? formatMessage({
                        id: `error.${errors.oldPassword}`,
                        defaultMessage: errors.defaultErrorMessage,
                      })
                    : ''
                }
              />
              <PasswordField
                name="newPassword"
                fieldLabel={formatMessage({ id: 'changePassword.newPassword' })}
                helperText={
                  errors.newPassword && touched.newPassword
                    ? formatMessage({
                        id: `error.newPassword.${errors.newPassword}`,
                        defaultMessage: errors.defaultErrorMessage
                          ? errors.defaultErrorMessage
                          : formatMessage({ id: 'mailSignup.passwordHelp' }),
                      })
                    : ''
                }
              />
              <PasswordField
                name="confirmNewPassword"
                fieldLabel={formatMessage({ id: 'changePassword.confirmNewPassword' })}
                helperText={
                  errors.confirmNewPassword && touched.confirmNewPassword
                    ? formatMessage({
                        id: `error.newPassword.${errors.confirmNewPassword}`,
                        defaultMessage: errors.defaultErrorMessage
                          ? errors.defaultErrorMessage
                          : formatMessage({ id: 'mailSignup.passwordHelp' }),
                      })
                    : ''
                }
              />
              <Container style={{ width: '100%', margin: 0, padding: '0px 0px 0px 15px' }}>
                <LoaderButton loading={isSubmitting} success={status}>
                  <Typography variant="h2">
                    {formatMessage({
                      id: 'button.next',
                    })}
                  </Typography>
                </LoaderButton>
              </Container>
            </Form>
          </Container>
        </Container>
      )}
    </Formik>
  );
};

export default ChangePassword;
