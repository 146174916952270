import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { AuthError, useUser } from 'hooks/UserContext';
import { useRedirect } from 'hooks/RedirectContext';
import ResendCodeButton from 'components/Buttons/ResendCodeButton';

const useStyles = makeStyles(() => ({
  messageDisplay: { marginTop: 10, marginLeft: 15, marginBottom: 0 },
  messageInfo: {},
  messageError: {
    color: 'red',
  },
}));
const ResendSignUpButtonWrapper: React.FC<{ username: string }> = ({ username }) => {
  const { resendSignUp } = useUser();
  const { getRedirectUri } = useRedirect();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [displayMessage, setDisplayMessage] = useState('');
  const [displayMessageClass, setDisplayMessageClass] = useState(classes.messageInfo);

  const handleResendCode = async () => {
    try {
      await resendSignUp(username, getRedirectUri() ?? '');
      setDisplayMessage(formatMessage({ id: 'confirm.resendCodePrompt' }));
      setDisplayMessageClass(classes.messageInfo);
    } catch (e) {
      const err = e as AuthError;
      setDisplayMessageClass(classes.messageError);
      setDisplayMessage(err.message);
    }
  };
  return (
    <div>
      <ResendCodeButton onClick={handleResendCode} />
      <div className={`${classes.messageDisplay} ${displayMessageClass}`}>{displayMessage}</div>
    </div>
  );
};

export default ResendSignUpButtonWrapper;
