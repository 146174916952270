import React from 'react';
import { AppBar, Button, Theme, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

interface AppBarProps {
  handleCancelClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  backBar: {
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(242, 242, 242, 1)',
    },
    height: 54,
    background: 'rgba(77, 77, 79, 1)',
  },
  backToolBar: {
    height: 54,
    verticalAlign: 'center',
    padding: 0,
  },
  backArrow: {
    [theme.breakpoints.down('xs')]: {
      color: 'rgba(41, 41, 41, 1)',
    },
    color: 'rgba(255, 255, 255, 1)',
    marginLeft: 24,
    marginRight: 16,
    width: 21,
  },
  backText: {
    [theme.breakpoints.down('xs')]: {
      color: 'black',
    },
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const CustomAppBar: React.FC<AppBarProps> = ({ handleCancelClick }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <AppBar position="relative" className={classes.backBar}>
      <Toolbar variant="dense" className={classes.backToolBar}>
        <Button style={{ background: 'transparent' }} onClick={handleCancelClick} disableRipple>
          <ArrowBackIcon className={classes.backArrow} />
          <Typography variant="h3" className={classes.backText}>
            {formatMessage({ id: 'back' })}
          </Typography>
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
