import React from 'react';

import { useIntl } from 'react-intl';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Toolbar, FormControl, TextField, InputAdornment, Button, Grid } from '@material-ui/core';
import { parseInvalidPasswordException, parseInvalidParameterException } from 'utils/errors';

import LoaderButton from 'components/Buttons/LoaderButton';
import { AuthError, useUser } from 'hooks/UserContext';
import { appBarHeight } from 'App';
import useTouch from 'hooks/useTouch';
import RequirementsTooltip from 'components/Tooltips/PasswordRequirementsToolTip';
import { ReactComponent as PasswordSee } from '../../../../img/passwordsee.svg';
import { ReactComponent as PasswordNoSee } from '../../../../img/passwordnosee.svg';

YupPassword(Yup);

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).max(256).minLowercase(1).minUppercase(1).minNumbers(1).required('required'),
});

interface CrmForgotPasswordValues {
  password: string;
  defaultErrorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  confirmTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },
  backBar: {
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(242, 242, 242, 1)',
    },
    height: 54,
    background: 'rgba(77, 77, 79, 1)',
  },
  backToolBar: {
    height: 54,
    verticalAlign: 'center',
    padding: 0,
  },
  confirmBody: {
    paddingTop: 40,
    padding: 48,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  adornedNoPadding: {
    paddingRight: 0,
  },
  redStar: {
    color: 'red',
  },
}));

// Alternate flow for when a user with only a CRM account uses "ForgotPassword".
const CrmForgotPassword: React.FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { user, completeNewPassword } = useUser();
  const [isTouched, touchEventListeners] = useTouch();

  const handleSubmit = async (
    values: CrmForgotPasswordValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<CrmForgotPasswordValues>,
  ) => {
    try {
      await completeNewPassword(user!, values.password);
      setStatus(true);
      setSubmitting(false);
    } catch (e) {
      const err = e as AuthError;
      if (err.name === 'InvalidPasswordException') {
        setErrors({ password: parseInvalidPasswordException(err), defaultErrorMessage: err.message });
      } else if (err.name === 'InvalidParameterException') {
        setErrors({ password: parseInvalidParameterException(err), defaultErrorMessage: err.message });
      } else {
        setErrors({ password: err.name, defaultErrorMessage: err.message });
      }
      setStatus(true);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        {
          password: '',
          defaultErrorMessage: '',
        } as CrmForgotPasswordValues
      }
      validationSchema={NewPasswordSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Container component="main" maxWidth={false} className={classes.confirmTab}>
          <AppBar position="relative" className={classes.backBar}>
            <Toolbar variant="dense" className={classes.backToolBar} />
          </AppBar>
          <Container className={classes.confirmBody}>
            <Typography variant="h1">{formatMessage({ id: 'newpassword.title' })}</Typography>

            <Form>
              <FormControl
                style={{
                  marginTop: 24,
                  borderLeft: errors.password && touched.password ? '3px solid #EA1E27' : '3px solid transparent',
                  paddingLeft: 15,
                }}
                fullWidth
              >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} md={8}>
                    <Typography variant="h3" style={{ marginBottom: 12 }}>
                      {formatMessage({ id: 'mailSignup.choosePassword' })} <span className={classes.redStar}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} style={{ paddingLeft: 15 }}>
                    <RequirementsTooltip />
                  </Grid>
                </Grid>
                <TextField
                  id="password"
                  name="password"
                  variant="outlined"
                  type={isTouched ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" {...touchEventListeners}>
                        <Button style={{ background: 'transparent', textTransform: 'none' }} disableRipple>
                          {isTouched ? <PasswordNoSee /> : <PasswordSee />}
                        </Button>
                      </InputAdornment>
                    ),

                    classes: {
                      adornedEnd: classes.adornedNoPadding,
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorText,
                    },
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password
                      ? formatMessage({
                          id: `error.${errors.password}`,
                          defaultMessage: errors.defaultErrorMessage
                            ? errors.defaultErrorMessage
                            : formatMessage({ id: 'mailSignup.passwordHelp' }),
                        })
                      : ''
                  }
                  required
                />
              </FormControl>

              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({ id: 'confirm.confirm' })}
              </LoaderButton>
            </Form>
          </Container>
        </Container>
      )}
    </Formik>
  );
};

export default CrmForgotPassword;
