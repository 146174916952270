import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { AuthError, useUser } from '../../../hooks/UserContext';
import ResendCodeButton from '../../../components/Buttons/ResendCodeButton';

const useStyles = makeStyles(() => ({
  messageDisplay: { marginTop: 10, marginLeft: 15, marginBottom: 0 },
  messageInfo: {},
  messageError: {
    color: 'red',
  },
}));

const ResendEmailChangeButtonWrapper: React.FC = () => {
  const { resendUserAttributeConfirmationCode } = useUser();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [displayMessage, setDisplayMessage] = useState('');
  const [displayMessageClass, setDisplayMessageClass] = useState(classes.messageInfo);

  const handleResendCode = async () => {
    try {
      resendUserAttributeConfirmationCode('email');
      setDisplayMessage(formatMessage({ id: 'confirm.resendCodePrompt' }));
      setDisplayMessageClass(classes.messageInfo);
    } catch (e) {
      const err = e as AuthError;
      setDisplayMessageClass(classes.messageError);
      if (err.message === 'newEmailUserNotFound') {
        setDisplayMessage(formatMessage({ id: `error.${err.message}` }));
      } else {
        setDisplayMessage(err.message);
      }
    }
  };
  return (
    <div>
      <ResendCodeButton onClick={handleResendCode} />
      <div className={`${classes.messageDisplay} ${displayMessageClass}`}>{displayMessage}</div>
    </div>
  );
};

export default ResendEmailChangeButtonWrapper;
