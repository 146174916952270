export default {
  send: 'Send',
  email: 'E-mail',
  back: 'Back',
  signWithEmail: 'Register with email',
  tabLogin: 'Log in',
  tabSign: 'Register',
  newPassword: 'New password',
  newEmailTextField: 'New e-mail',
  confirmNewEmailTextField: 'Confirm new e-mail',

  confirm: {
    confirm: 'Confirm',
    finish: 'Finish',
    code: 'Verification code',
    title: 'Please fill in verification code',
    text1: 'We have just sent you an e-mail.',
    text2: 'Please check your mailbox and follow the instructions to verify your account.',
    text3: 'Note that the e-mail might be in your spam folder.',
    required: 'Verification code is a required field',
    resendCode: 'Resend code',
    resendCodePrompt: 'A new verification code has been sent, please check your inbox.',
  },

  newpassword: {
    title: 'Please enter your new password',
    minRequirements: 'Minimum requirements: 8 characters, 1 uppercase, 1 lowercase, 1 number',
  },

  changePassword: {
    text: `Please first enter your old password, then pick a new one. 
    You'll then be required to log in again with your new password.`, //
    oldPassword: 'Old password', //
    newPassword: 'New password', //
    confirmNewPassword: 'Confirm new password', //
  },

  button: {
    cancel: 'Cancel',
    cancelRequest: 'Cancel Request',
    continue: 'Continue',
    next: 'Next',
    finish: 'Finish',
  },

  exit: {
    logoutTitle: 'You are logged out',
    loginTitle: 'You are logged in',
    exitMessage1: `Please wait while we redirect you to our website. 
    In case you are not redirected automatically, click here to return to the`,
    exitMessage2: 'Niko Website.',
  },

  login: {
    password: 'Password',
    connect: 'Log in',
    connectUsing: 'Log in using',
    withEmail: 'or with email',
    passwordHelp: 'Min: 8 characters including 1 upper case, 1 lower and 1 number',
    logout: 'Log out',
    remember: 'Remember my information',
    forgot: 'Forgot Password',
  },

  signup: {
    with: 'Register with ',
    or: 'or',
  },

  reset: {
    resetPassword: 'Forgot your password?',
    resetPasswordText: `Enter the e-mail address associated with your account. 
    We will send you a verification code to reset your password.`,
    temporaryPassword: 'Please check your mailbox for a temporary password',
    confirmResetPasswordText1: 'A verification code has been sent to:',
    confirmResetPasswordText2: 'Please check your mailbox and follow the instructions to reset your password. ',
    confirmResetPasswordText3: 'Note that the e-mail might be in your spam folder',
    goToLogin: 'Go back to login',
  },

  mailSignup: {
    signUp: 'Register',
    firstName: 'First name',
    lastName: 'Last name',
    choosePassword: 'Password',
    hide: 'Hide',
    show: 'Show',
    passwordHelp: 'Min: 8 characters, 1 upper- and lowercase, and 1 number',
    country: 'Country',
    language: 'Language',
    receiveInfo: 'I want to receive Niko information',
    textPrivacyPolicy: 'By submitting this form you agree Niko may process your personal data according to our ',
    privacyPolicy: 'privacy policy',
    termsOfUse1: 'I understand and accept the',
    termsOfUse2: 'terms of use',
    mandatoryFields: 'Mandatory fields',
    dataTreatment: ' its privacy policy',
  },

  language: {
    french: 'French',
    english: 'English',
    swedish: 'Swedish',
    danish: 'Danish',
    slovak: 'Slovak',
    dutch: 'Dutch',
    german: 'German',
  },

  country: {
    belgium: 'Belgium',
    denmark: 'Denmark',
    france: 'France',
    sweden: 'Sweden',
    slovakia: 'Slovakia',
    germany: 'Germany',
    netherland: 'Netherland',
  },

  error: {
    required: 'Required field',
    apicall: 'Something went wrong on api server!',
    UserNotFoundException: 'User does not exist.',
    NotAuthorizedException: 'Incorrect username or password.',
    NotAuthorizedExceptionReset: 'Incorrect username.',
    UsernameExistsException: 'An account with the given email already exists.',
    InvalidParameterExceptionPassword: `Password did not conform with policy: Password must :
        have a length greater than or equal to 8, 
        contains 1 upper- and lowercase,
        1 number.`,
    InvalidPasswordExceptionLong: 'Password did not conform with policy: Password not long enough.',
    InvalidPasswordExceptionLower: 'Password did not conform with policy: Password must have lowercase characters.',
    InvalidPasswordExceptionUpper: 'Password did not conform with policy: Password must have uppercase characters.',
    InvalidPasswordExceptionNumeric: 'Password did not conform with policy: Password must have numeric characters.',
    CodeMismatchException: 'Invalid verification code provided, please try again.',
    email: 'Missing',
    confirmationCode: 'Missing',
    AuthError: 'Wrong confirmation code',
    crmError: 'An unexpected error occurred during login, please contact Niko to resolve the issue.',
    invalidPassword: 'Your password has expired, an email was sent to you with further instructions.',
    // WARNING: CHangeEmail component expects these specific names for Yup.
    // If you change them, you must modify the Yup schema too.
    newEmail: {
      newEmailMismatch: 'E-mails must match',
      newEmailBasic: 'Invalid field',
      newEmailWrongFormat: 'Not a valid e-email format',
      newEmailUserNotFound: 'You must be logged in to update your e-mail.',
    },
    newPassword: {
      newPasswordMismatch: 'Passwords must match', //
      newPasswordBasic: 'Invalid field', //
      newPasswordUserNotFound: 'You must be logged in to update your password.', //
    },
  },
};
